import { CommonService } from "../../core/services";
import { Observable } from "rxjs";
import { PatientsTagModel2, } from "../../client-portal/patient-profile/patient-tag.model";
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/common.service";
//import { AppointmentModel } from '../../scheduling/scheduler/add-appointment/appointment.model';
var ClientsService = /** @class */ (function () {
    function ClientsService(commonService) {
        this.commonService = commonService;
        this.getPatientFamilyEducation = "Drugs/GetAllPatientDrugFamilyEducation";
        this.GetStaffAvailabilityMobileURL = "AvailabilityTemplates/GetStaffAllAvailabilityMobile";
        this.GetOpenShiftURL = "AvailabilityTemplates/GetOpenShifts";
        this.GetStaffAvailabilityURL = "AvailabilityTemplates/GetStaffAvailability";
        this.GetStaffUnAvailabilityURL = "AvailabilityTemplates/GetStaffUnAvailability";
        this.approveStaffAvailabilityURL = "AvailabilityTemplates/ApproveStaffAvailability";
        this.approveStaffUnAvailabilityURL = "AvailabilityTemplates/ApproveStaffUnAvailability";
        this.getMasterDataByNameURL = "api/MasterData/MasterDataByName";
        this.getMasterInventoryTypeDataURL = "api/MasterData/GetMasterInventoryType";
        this.getMasterSuppliesDataURL = "api/MasterData/GetMasterSuppliesData";
        this.getMasterSuppliesMedicalDataURL = "api/MasterData/GetMasterSuppliesById";
        this.getMasterSuppliesMedicationsDataURL = "api/MasterData/GetMasterSuppliesmedicalById";
        this.getMasterSuppliesMedicationsListDataURL = "api/MasterData/GetMasterSuppliesmedicationsById";
        this.createURL = "Patients/CreateUpdatePatient";
        this.getClientByIdURL = "Patients/GetPatientById";
        this.getPatientsTagsByIdURL = "Patients/GetPatientTagData";
        this.getPatientsNotesByIdURL = "Patients/GetPatientNotesData";
        this.getFollowUpByClientIdURL = "Patients/GetFollowUpAppointmentDetails";
        this.getClientHeaderInfoURL = "Patients/GetPatientHeaderInfo";
        this.getClientProfileInfoURL = "Patients/GetPatientById";
        this.getpatientdetailsurl = "Patients/GetPatientsDetails";
        this.getCheckHealthCardPatientUrl = "Patients/CheckHealthCardPatient";
        this.getPatientHistoryInfoUrl = "PatientHistory/GetPatientHistory";
        this.updateClientStatusURL = "patients/UpdatePatientActiveStatus";
        this.updateUserStatusURL = "user/UpdateUserStatus";
        this.getPatientCCDAURL = "patients/GetPatientCCDA";
        this.getPatientImmunizationURL = "patients/PatientImmunization";
        this.savetPatientImmunizationURL = "patients/SavePatientImmunization";
        this.deletePatientImmunizationURL = "patients/DeletePatientImmunization";
        this.unblockResident = "ConcentPolicy/UnblockPatient";
        this.updatePatientPortalVisibilityURL = "patients/UpdatePatientPortalVisibility";
        this.getEMRPatientMedicationPassProgressListURL = "PatientsMedication/GetEMRPatientMedicationPassProgressList";
        this.getEMRPatientMissedMedicationListURL = "PatientsMedication/GetEMRPatientMissedMedicatioList";
        this.getEMRPatientReminderListURL = "PatientsMedication/GetAllReminderList";
        this.getEMRPatientPRNAdministeredListURL = "PatientsMedication/GetEMRPatientPRNAdministeredList";
        this.getEMRPatientMedicationDiscontinueOrderListURL = "PatientsMedication/GetEMRPatientMedicationListDiscontinueOrder";
        this.getEMRPatientMedicationIncidentReportLogListURL = "PatientsMedication/GetMedicationIncidentReportLog";
        this.getEMRMedicationReorderListURL = "PatientsMedication/GetAllMedicationReorderList";
        this.createUpdateNurseOrderURL = "Plan/CreateUpdateNurseOrder";
        this.createPatientHistoryUrl = "PatientHistory/SavePatientHistory";
        this.deleteReferralURL = "PatientReferral/DeleteReferral";
        this.getReferralByIdURL = "PatientReferral/GetPatientReferalDataById";
        //Get Availbale Time for Patient's EMAR list
        this.getStaffAvailableTimeURL = "AvailabilityTemplates/GetStaffAvailableTime";
        //Point of care
        this.createUpdateAdlTrackingToolUrl = "PointOfCare/SaveUpdateADLTrackingTool";
        this.ADLTrackingToolIsCrossURL = "NoticeBoard/ADLTrackingToolIsCross";
        this.updateAdlTrackingToolCrossOutReasonUrl = "PointOfCare/UpdateAdlTrackingToolCrossOutReason";
        this.SaveUpdateMoodAndBehaviorUrl = "PointOfCare/SaveUpdateMoodAndBehavior";
        this.updateMabTrackingToolCrossOutReasonUrl = "PointOfCare/UpdateMabCrossOutReason";
        this.BehaviorTrackingToolCrossURL = "NoticeBoard/BehaviorTrackingToolCross";
        this.getAdlTrackingToolUrl = "PointOfCare/GetAllADLTrackingTool";
        this.deleteAdlTrackingToolUrl = "PointOfCare/DeleteADLTrackingToolById";
        this.cdssFallMessage = "SafetyAndSecurity/GetCDSSMessageForFall";
        this.cdssPressureUlcerMessageURL = "SafetyAndSecurity/GetCDSSMessageForPressureUlcerNew";
        this.cdssBehaviorMessageURL = "SafetyAndSecurity/GetCDSSMessageForBehavior";
        this.cdssActivityMessageURL = "SafetyAndSecurity/GetCDSSMessageForActivity";
        this.cdssNewPainMessageURL = "SafetyAndSecurity/GetCDSSMessageForNewPain";
        this.cdsOngoingPainMessageURL = "SafetyAndSecurity/GetCDSSMessageForOngoingPain";
        this.cdssPressureUlcerOngoingMessageURL = "SafetyAndSecurity/GetCDSSMessageForPressureUlcerOngoing";
        this.getAdlTrackingToolUrlByPatientId = "PointOfCare/GetADLTrackingToolByPatientId";
        this.getStatusOfPOCUrlByPatientId = "PersonalHygiene/GetNCFIsDraftStatusMobile";
        this.getESASRatingGraphDataUrl = "ESASRatings/GetESASRatingsByPatientId";
        this.GetViewESASRatingsByPatientId = "ESASRatings/GetViewESASRatingsByPatientId";
        this.getESASRatingCurrentDayGraphDataUrl = "ESASRatings/GetESASCurrentDateDays";
        this.createsafetyAndSecurityNursingFlowUrl = "SafetyAndSecurity/SaveUpdateSafetyAndSecurity";
        this.updateNurisngCareFlowCrossOutReasonUrl = "SafetyAndSecurity/UpdateNurisngCareFlowCrossOutReason";
        this.PreviousNursingCareFlowIsCrossURL = "NoticeBoard/PreviousNursingCareFlowIsCross";
        this.getSafetyAndSecurityByPatientIdUrl = "SafetyAndSecurity/GetSafetyAndSecurityByPatientId";
        this.getMoodAndBehaviorByPatientIdUrl = "PointOfCare/GetMoodAndBehaviorByPatientId";
        this.DiscardMoodAndBehaviorByIdURL = "PointOfCare/DiscardMoodAndBehaviorById";
        // private getESASRatingsUrlByPatientId =
        //   "ESASRatings/GetESASRatingsByPatientId";
        this.getESASRatingsUrlByPatientId = "ESASRatings/GetESASRatingsByPatientId";
        this.getViewESASRatingsUrlByPatientId = "ESASRatings/GetViewESASRatingsByPatientId";
        this.getPatientLocationByPatientId = "MasterShift/GetPatientLocationById";
        this.getPatientUnitByPatientId = "MasterShift/GetPatientUnitById";
        this.GetESASRatingsByPatientIdAsDraft = "ESASRatings/GetESASRatingsByPatientIdAsDraft";
        this.SaveUpdateESASRatingsUrl = "ESASRatings/SaveUpdateESASRatings";
        this.saveUpdateAsDraftUrl = "ESASRatings/SaveUpdateESASRatingsSaveAsDraft";
        this.DeleteESASRatingsByIdUrl = "ESASRatings/DeleteESASRatingsById";
        this.createPersonalHygieneNursingFlowUrl = "PersonalHygiene/SaveUpdatePersonalHygiene";
        this.getPersonalHygieneNursingCareFlowUrl = "PersonalHygiene/GetPersonalHygieneByPatientId";
        this.createActivityExersiceNursingFlowUrl = "ActivityExercise/SaveUpdateActivityExercise";
        this.getActivityExersiceNursingFlowUrlByPatientId = "ActivityExercise/GetActivityExerciseByPatientId";
        this.createSleepAndComfortNursingFlowUrl = "SleepRestAndComfort/SaveUpdateSleepRestAndComfort";
        this.createUpdateIncidentReportUrl = "IncidentReport/SaveUpdateIncidentReport";
        this.updateIncidentCrossOutReasonUrl = "IncidentReport/UpdateIncidentCrossOutReason";
        this.getSleepAndRestByPatientIdUrl = "SleepRestAndComfort/GetSleepRestAndComfortByPatientId";
        this.SaveUpdateNutritionAndHydrationURL = "NutritionAndHydration/SaveUpdateNutritionAndHydration";
        this.getNutritionAndHydrationNursingCareFlowUrl = "NutritionAndHydration/GetNutritionAndHydrationSaveAsDraft";
        //Eligibility Enquiry Methods
        this.getPayerByPatientURL = "GetPayerByPatient";
        this.getEligibilityEnquiryServiceCodesURL = "EligibilityCheck/GetEligibilityEnquiryServiceCodes";
        this.download270URL = "EligibilityCheck/Download270";
        //Allergy URL
        this.createAllergyURL = "PatientsAllergy/SaveAllergy";
        this.createCheckAllergyURL = "PatientsAllergy/SaveAllergyCheck";
        this.getAllergyListURL = "PatientsAllergy/GetAllergies";
        this.getAllergyCheckListURL = "PatientsAllergy/GetAllergiesCheck";
        this.getAllergyByIdURL = "PatientsAllergy/GetAllergyById";
        this.deleteAllergyURL = "PatientsAllergy/DeleteAllergy";
        //mastershift
        this.getMasterShiftDataURL = "MasterShift/GetShiftByLocationIds";
        this.getMasterShiftDataByUnitURL = "MasterShift/GetShiftByUnitId";
        this.getStaffShiftDataByUnitURL = "MasterShift/GetStaffShiftByUnitId";
        this.GetShiftByUnitIdDataURL = "MasterShift/GetShiftByUnitId";
        // Inventory URL
        this.getpersonalinventoryURL = "PersonalInventory/GetPersonalInventory";
        this.getPersonalInventoryByIdURL = "PersonalInventory/GetPersonalInventoryById";
        this.createInventoryURL = "PatientsAllergy/SaveInventory";
        this.createPersonalnventoryURL = "PersonalInventory/SavePersonalInventory";
        this.createUnitMasterURL = "AdminDashboard/SaveUnitMaster";
        this.createLabResultURL = "PatientReferral/SaveLabResultRecord";
        this.createDocumentResultURL = "PatientReferral/SaveDocumentResultRecord";
        this.createReferralResultURL = "PatientReferral/SaveReferralResultRecord";
        this.createLocalResourcesMasterURL = "Patients/SaveLocalResourcesData";
        this.createCareCoordinatorMasterURL = "Patients/SaveCareCoordinatorData";
        this.createPatientCareProviderURL = "Patients/SavePatientCareProviderData";
        this.createPatientTagURL = "Patients/SavePatientTagData";
        this.createPatientNotesURL = "Patients/SavePatientNotesData";
        this.createManageTagsURL = "MasterTag/SaveMasterTag";
        this.createMasterTaxURL = "MasterTaxDetail/SaveMasterTaxDetails";
        this.subscriptionDetailsURL = "SubscriptionDetails/SaveUpdateSubscriptionsPaymentDetails";
        this.createMasterDiscountURL = "MasterDiscountDetail/SaveMasterDiscountDetails";
        this.createPatientAppointmentURL = "Patients/SavePatientsAppointmentsData";
        //Authorization
        this.getAllAuthorizationsForPatientURL = "Authorizations/GetAllAuthorizationsForPatient";
        this.getAuthorizationByIdURL = "Authorizations/GetAuthorizationById";
        this.deleteAuthorizationURL = "Authorizations/DeleteAutorization";
        this.getPatientPayerServiceCodesAndModifiersURL = "patients/GetPatientPayerServiceCodesAndModifiers";
        this.createAuthorizationURL = "Authorizations/SaveAuthorization";
        //referURL
        this.createReferURL = "PatientReferral/SavePatientReferalData";
        this.createIncomingReferURL = "PatientReferral/SavePatientIncomingReferalData";
        this.createMealPlanURL = "MealPlan/SaveUpdateMealPlan";
        this.createMealCategoryURL = "MealPlan/SaveUpdateMealCategory";
        this.createMealItemURL = "MealPlan/SaveUpdateMealItem";
        this.createPatientTransferURL = "Patients/SavePatientTransferData";
        this.createIncidentLogReportURL = "PatientsMedication/SaveMedicationIncidentLog";
        //ShiftById
        this.getshiftbyId = "MasterShift/GetShiftWithShiftId";
        //Vitals URL
        this.createVitalURL = "PatientsVitals/SaveVital";
        this.createTARUrl = "TAR/CreateTAR";
        this.updateTarCrossOutReasonUrl = "TAR/UpdateTarCrossOutReason";
        this.createPatientServiceFeedBackURL = "Patients/SavePatientServiceFeedBack";
        this.getVitalListURL = "PatientsVitals/GetVitals";
        this.getVitalGraphURL = "PatientsVitals/GetVitalsGraphData";
        this.getVitalByIdURL = "PatientsVitals/GetVitalById";
        this.deleteVitalURL = "PatientsVitals/DeleteVital";
        this.getLatestVitalByIdURL = "PatientsVitals/GetLatestVitalById";
        //Lab Documents URl
        this.getLabDocumentURL = "PatientResultLabDocument/GetPatientResultLabDocument";
        this.getRadiologyReportURL = "PatientResultRadiologyReport/GetPatientResultRadiologyReportDocument";
        this.getRadiologyDicomURL = "PatientResultRadiologyDicom/GetPatientResultRadiologyDicomDocument";
        this.getCardiacDocumentURL = "PatientResultCardiacDocument/GetPatientCardiacDocument";
        this.getLabsResultDocumentURL = "PatientResultCardiacDocument/GetPatientLabsResultDocument";
        this.getPathologyDocumentURL = "PatientResultPathology/GetPatientResultPathologyDocument";
        this.getOtherDocumentURL = "PatientResultOtherDocument/GetPatientResultOtherDocument";
        this.deleteRadiologyDocumentURl = "PatientResultRadiologyReport/DeleteRadiologyReportDocument";
        this.deleteRadiologyDicomURl = "PatientResultRadiologyDicom/DeleteRadiologyDicomDocument";
        this.deletePathologyDocumentURl = "PatientResultPathology/DeleteResultPathologyDocument";
        this.deleteCardiacDocumentURl = "PatientResultCardiacDocument/DeleteResultCardiacDocument";
        this.deletePatientLabDocumentURl = "PatientResultLabDocument/DeleteResultLabDocument";
        this.deletePatientOtherDocumentURl = "PatientResultOtherDocument/DeleteResultOtherDocument";
        //CarePlanGoalURL
        this.getCarePlanGoalByIdURL = "CarePlanGoalController/GetCarePlanGoalById";
        this.getCarePlanGoalByGoalIdURL = "CarePlanGoalController/GetCarePlanGoalByGoalId";
        this.deleteCarePlanGoalByGoalIdURL = "CarePlanGoalController/DeleteCarePlanGoal";
        this.createCarePlanGoalURl = "CarePlanGoalController/CreateUpdateCarePlanGoal";
        //Immunization URL
        this.createImmunizationURL = "PatientsImmunization/SavePatientImmunization";
        this.getImmunizationListURL = "PatientsImmunization/GetImmunization";
        this.getImmunizationByIdURL = "PatientsImmunization/GetImmunizationById";
        this.deleteImmunizationURL = "PatientsImmunization/DeleteImmunization";
        this.deleteCurrentmedicationURL = "Plan/DeleteCurrentmedication";
        //OverCounterMedication URL
        this.createSaveOverCounterMedicationURL = "PatientsMedication/SaveOverCounterMedication";
        this.createSaveHerbalSupplementURL = "PatientsMedication/SaveHerbalSupplement";
        this.getOverCounterMedicationURL = "PatientsMedication/GetOverCounterMedication";
        this.getMedicationNameListURL = "PatientsMedication/GetMedicationNameList";
        this.getLevelOfEducationListURL = "Patients/GetLevelofEducationList";
        this.getAllergyTypeListtURL = "PatientsAllergy/GetAllergyTypeList";
        this.getAllernListtURL = "PatientsAllergy/GetAllergyTypeList";
        this.getDiagnosisListtURL = "PatientsDiagnosis/GetDiagnosisTypeList";
        this.getSurgeryListURL = "PatientsDiagnosis/GetSurgeryList";
        // private getAllernListtURL = "PatientsAllergy/GetAllergenList";
        this.getSeverityListtURL = "PatientsAllergy/GetSeverityList";
        this.getAllergyStatusListURL = "PatientsAllergy/GetAllergyStatusList";
        this.getMedicationListbyPatientIdURL = "PatientsMedication/GetMedication";
        this.getHerbalSupplementURL = "PatientsMedication/GetHerbalSupplement";
        // private getMedicationByIdURL = "PatientsMedication/GetMedicationById";
        //private deleteMedicationURL = "PatientsMedication/DeleteMedication";
        //Medication URL
        this.createMedicationURL = "PatientsMedication/SaveMedication";
        this.createEmarMedicationURL = "PatientsMedication/SaveEmarMedication";
        this.geteEmarMedicationURL = "PatientsMedication/GetEmarMedication";
        this.geteMealPlanPopUpDataURL = "MealPlan/GetAllMealPlanListPopUpData";
        this.createUpdateERXURL = "Plan/CreateUpdateERx";
        this.getMedicationListURL = "PatientsMedication/GetMedication";
        this.GetMedicationNamesListURL = "PatientsMedication/GetMedicationNames";
        this.getMedicationByIdURL = "PatientsMedication/GetMedicationById";
        this.deleteMedicationURL = "PatientsMedication/DeleteMedication";
        this.getherbalMedicationByIdURL = "PatientsMedication/GetHerbalMedicationById";
        this.deleteherbalMedicationURL = "PatientsMedication/DeleteHerbalMedication";
        this.getoverMedicationByIdURL = "PatientsMedication/GetOverMedicationById";
        this.deleteoverMedicationURL = "PatientsMedication/DeleteOverMedication";
        this.getTreatmentTimeLineURL = "Patients/GetPatientTeatmentTimelineById";
        this.getPatientMedicationListURL = "PatientsMedication/GetMedicationByPatientId";
        this.getEMRPatientListURL = "PatientsMedication/GetEMRPatientList";
        this.getEMRPatientDateListURL = "PatientsMedication/GetEMRPatientListByDueTime";
        this.getAllCurrentMedicationPrescribingHistoryURL = "Plan/GetAllCurrentMedicationPrescribingHistory";
        this.getAllPreviousMedicationPrescribingHistoryURL = "Plan/GetAllPrescribingMedicationPrescribingHistory";
        this.getOutgoingReferralListURL = "PatientReferral/GetPatientReferalData";
        this.getIncomingReferralDataURL = "PatientReferral/GetPatientIncomingReferalData";
        // private getAllCurrentMedicationPrescribingHistoryURL = "Plan/GetAllCurrentMedicationPrescribingHistory";
        this.getFaxAchievesDataURL = "PatientReferral/GetFaxAchievesData";
        //Guardian URL
        this.createGuardianURL = "PatientsGuardian/CreateUpdatePatientGuardian";
        this.getGuardianListURL = "PatientsGuardian/GetPatientGuardian";
        this.getGuardianByIdURL = "PatientsGuardian/GetPatientGuardianById";
        this.deleteGuardianURL = "PatientsGuardian/DeletePatientGuardian";
        //Address URL
        this.getAddressAndPhoneNumbersURL = "PatientsAddress/GetPatientPhoneAddress";
        this.saveAddressAndPhoneNumbersURL = "PatientsAddress/SavePhoneAddress";
        // social history
        this.getPatientSocialHistoryURL = "PatientsSocialHistory/GetPatientSocialHistory";
        this.savePatientSocialHistoryURL = "PatientsSocialHistory/SavePatientSocialHistory";
        //Diagnosis URL
        this.getDiagnosisListURL = "PatientsDiagnosis/GetDiagnosis";
        this.getDiagnosisByIdURL = "PatientsDiagnosis/GetDiagnosisById";
        this.createDiagnosisURL = "PatientsDiagnosis/SavePatientDiagnosis";
        this.deleteDiagnosisURL = "PatientsDiagnosis/DeleteDiagnosis";
        //Patient Insurance URL
        this.getPatientInsurances = "PatientsInsurance/GetPatientInsurances";
        this.savePatientInsurances = "PatientsInsurance/SavePatientInsurance";
        // Patient Custom Label
        this.getPatientCustomLabels = "PatientsCustomLabel/GetPatientCustomLabels";
        this.savePatientCustomLabels = "PatientsCustomLabel/SavePatientCustomLabels";
        // Patient Family History URL
        this.getPatientMedicalFamilyHistoryListURL = "PatientMedicalFamilyHistory/GetPatientMedicalFamilyHistoryById";
        this.deletePatientMedicalFamilyHistoryURL = "PatientMedicalFamilyHistory/DeletePatientMedicalFamilyHistory";
        this.savePatientFamilyHistoryDataURL = "PatientMedicalFamilyHistory/SavePatientMedicalfamilyHistory";
        this.getAllPatientFamilyHistoryURL = "PatientMedicalFamilyHistory/GetAllPatientFamilyHistory";
        //surgical history url
        this.savePatientSurgicalHistoryDataURL = "PatientSurgicalHistory/SavePatientHospitalizationHistory";
        this.getAllPatientSurgicalHistoryListURL = "PatientSurgicalHistory/GetAllPatientSurgicalHistory";
        this.getPatientSurgicalHistoryByIdListURL = "PatientSurgicalHistory/GetPatientSurgicalHistoryById";
        this.deletePatientSurgicalHistoryByIdURL = "PatientSurgicalHistory/DeletePatientSurgicalHistory";
        // Patient Hospitilization Histroy
        this.SavePatientHospitalizationHistoryData = "/PatientsHospitilization/SavePatientHospitalizationHistory";
        this.DeletePatientHospitalizationHistoryData = "PatientsHospitilization/DeletePatientHospitalizationHistory";
        this.GetAllPatientHospitalizationHistoryData = "/PatientsHospitilization/GetAllPatientHospitalizationHistory";
        this.getPatientHospitilizationHistoryListURL = "PatientsHospitilization/GetPatientHospitilizationHistoryById";
        //Patient Encounters
        this.getPatientEncountersListURL = "/patient-encounter/GetPatientEncounter";
        //Ledger URL
        this.getClaimsForLedgerURL = "Claim/GetClaimsForPatientLedger";
        this.getClaimServiceLineForPatientLedgerURL = "Claim/GetClaimServiceLinesForPatientLedger";
        this.getPatientGuarantorURL = "patients/GetPatientGuarantor";
        this.getPaymentDetailByIdURL = "api/Payment/GetPaymentDetailsById";
        this.saveServiceLinePaymentURL = "api/Payment/SaveServiceLinePayment";
        this.deleteServiceLinePaymentURL = "api/Payment/DeleteServiceLinePayment";
        this.deleteInventoryURL = "PersonalInventory/DeleteInventory";
        //documents
        this.getUserDocumentsURL = "userDocument/GetUserDocuments";
        this.getPatientTransferDocumentURL = "ADT/GetTransferDocumentById";
        this.getUserCarePlanDocumentsURL = "userDocument/GetUserCarePlanDocuments";
        this.getWoundImgURL = "Assessment/GetWoundImages";
        this.deleteWoundImg = "Assessment/DeleteWoundImages";
        this.downloadWoundImg = "Assessment/DownloadWoundImage";
        this.getUserByLocationURL = "api/PatientAppointments/GetStaffAndPatientByLocation";
        this.getUserDocumentURL = "userDocument/GetUserDocument";
        this.deleteUserDocumentURL = "userDocument/DeleteUserDocument";
        this.uploadUserDocumentURL = "userDocument/UploadUserDocuments";
        this.patientResultLabDocumentURL = "PatientResultLabDocument/SavePatientResultLabDocument";
        this.patientResultRadiologyDicomURL = "PatientResultRadiologyDicom/SavePatientResultRadiologyDicomDocument";
        this.PatientResultRadiologyReportsURL = "PatientResultRadiologyReport/PatientResultRadiologyReportDocument";
        this.PatientCardiacReportsURL = "PatientResultCardiacDocument/SavePatientCardiacDocument";
        this.PatientPathologyReportsURL = "PatientResultPathology/SavePatientResultPathologyDocument";
        this.PatientOthersReportsURL = "PatientResultOtherDocument/SavePatientResultOtherDocument";
        this.getPatientLabDocumentURL = "PatientResultLabDocument/DownloadResultLabDocument";
        this.getPatientRadiologyDicomURL = "PatientResultRadiologyDicom/DownloadRadiologyDicomDocument";
        this.getPatientRadiologyReportURL = "PatientResultRadiologyReport/DownloadRadiologyReportDocument";
        this.getPatientCardiacReportURL = "PatientResultCardiacDocument/DownloadPatientCardiacDocument";
        this.getPatientPathologyReportURL = "PatientResultPathology/DownloadResultPathologyDocument";
        this.getPatientOtherReportURL = "PatientResultOtherDocument/DownloadPatientResultOtherDocument";
        //chat
        this.getChatHistoryURL = "Chat/GetChatHistory";
        this.getStateByCountryIdUrl = "api/MasterData/GetStateByCountryID";
        this.getRoomNUmberByUnitId = "Patients/GetRoomNumberByUnitId";
        this.getRoomNUmberByRoomId = "Patients/GetRoomNumberByRoomId";
        // PatientConsentsPreferences URL
        this.getPatientConsentsPreferencesURL = "Patients/GetPatientPreference";
        this.getPatientServiceFeedBackURL = "Patients/GetPatientServiceFeedBack";
        this.savePatientConsentsPreferencesURL = "Patients/SavePatientPreference";
        this.deletePatientConsentsPreferencesURL = "Patients/DeletePatientPreference";
        this.getPatientConsentsPreferencesByIdURL = "Patients/GetPatientPreferenceById";
        this.updatePatientPreferenceStatusURL = "Patients/UpdatePatientPreferenceStatusById";
        this.getRespiratoryAssessmentDropDownListURL = "Patients/GetPatientMasterProgressNotes";
        this.getStaffListURL = "Patients/GetAllStaffList";
        this.getProgressNoteURL = "Patients/GetPatientProgressNote";
        this.getTARUrl = "TAR/GetTARByPatientId";
        this.getTARbyDateURL = "TAR/GetTARSearchWithDate";
        this.getTARSearchURL = "TAR/GetTARSearch";
        this.deleteTarByIdtUrl = "TAR/DeleteTARById";
        this.getTARByIdUrl = "TAR/GetTARById";
        this.getAllIncidentReportURL = "IncidentReport/GetAllIncidentReport";
        this.deleteIncidentReportUrl = "IncidentReport/DeleteIncidentReportById";
        this.getIncidentReportByIdUrl = "IncidentReport/GetIncidentReportById";
        this.getProgressNoteSearchURL = "Patients/GetPatientProgressNoteSearch";
        this.getProgressNoteWithDateSearchURL = "Patients/GetPatientProgressNoteSearchWithDate";
        this.postSaveProgressNoteURL = "Patients/SaveProgressNote";
        this.updateProgressNoteCrossOutReasonUrl = "Patients/UpdateProgressNoteCrossOutReason";
        this.postSaveNoteAsDraftProgressNoteURL = "Patients/SaveProgressNoteAsDraft";
        this.getRespiratorySkinAssessmentDropDownListURL = "Patients/GetPatientSkinAssessment";
        this.getCardiovascularProgressNoteURL = "Patients/GetPatientProgressNoteForCardiovascular";
        this.getRespiratoryProgressNoteURL = "Patients/GetPatientProgressNoteForRespiratory";
        this.getGastroIntestinalProgressNoteURL = "Patients/GetPatientProgressNoteForGastroIntestinal";
        this.getGenitourinaryProgressNoteURL = "Patients/GetPatientProgressNoteForGenitourinary";
        this.getNeurologicalProgressNotesURL = "Patients/GetPatientProgressNoteForNeurology";
        this.getMusculokeletalProgressNoteURL = "Patients/GetPatientProgressNoteForMusculoskeletal";
        this.getpatientAdminissionDropDownListURL = "Patients/GetPatientsMDSMasterData";
        this.createMDSAdmissionURL = "Patients/SaveUpdateMDSAdmission";
        this.createAdtAdmissionURL = "ADT/SaveUpdateAdtAdmission";
        this.getAllAdmissionListURL = "ADT/GetAllAdmissionList";
        this.getAdmissionByIdURL = "/ADT/GetAdmissionById";
        this.deleteAdmissionDetailsURL = "/ADT/DeleteAdmissionDetailsById";
        this.createAdtDischargeURL = "ADT/SaveUpdatePatientDischarge";
        this.getAllDischargeListURL = "ADT/GetAllDischargeList";
        this.getDischargeDetailsByIdURL = "/ADT/GetDischargeDetailsById";
        this.deleteDischargeDetailsURL = "/ADT/DeleteDischargeDetailsById";
        this.getAllTransferListURL = "ADT/GetAllTransferList";
        this.getTransferDetailsByIdURL = "/ADT/GetTransferDetailsById";
        this.deleteTransferDetailsURL = "/ADT/DeleteTransferDetailsById";
        this.getHeathCardNumberMDSAdmissionURL = "Patients/GetPatientsMDSForAdmission";
        this.getPatientRecordSearchURL = "MealPlan/GetPatientsRecordSearch";
        this.getStaffRecordSearchURL = "MealPlan/GetStaffRecordSearch";
        this.createpatientdischargeURL = "Patients/SaveUpdateMDSAdmission";
        this.getHeathCardNumberMDSAdmissionCardByDataURL = "Patients/GetPatientsMDSForAdmissionUsingHCN";
        this.getStaffAvailabilityByLocationURL = "MasterShift/GetShiftWithShiftId";
        this.createCommunocationCarePlanURL = "CreateUpdateCommunicationCarePlan";
        //PLAN RX
        this.createRXURL = "Plan/CreateUpdateeRX";
        this.creatpRXURL = "Plan/CreateUpdatepRX";
        this.getCommunocationCarePlanURL = "GetCommunicationCarePlanByPatientId";
        //Care SUMMARY
        this.createCarePlanURL = "CarePlan/CreateUpdateCaresummary";
        this.getCareSummaryURL = "CarePlan/GetCaresummaryById";
        this.getTagsByPatientId = "Patients/GetTagsByPatientId";
        this.getCareSummaryGraphURL = "CarePlan/GetCaresummaryGraphByPatientId";
        this.createRoutineCarePlanURL = "CarePlan/CreateUpdateRoutinesCarePlan";
        this.getRoutineCarePlanURL = "CarePlan/GetRoutineCarePlanByPatientId";
        this.createCognitiveStatusCarePlanURL = "CarePlan/CreateUpdateCognitiveStatusCarePlan";
        this.getCognitiveStatusCarePlanURL = "CarePlan/GetCognitiveStatusCarePlanByPatientId";
        this.createBehavioursCarePlanURL = "CarePlan/CreateUpdateBehaviourCarePlan";
        this.getBehavioursCarePlanURL = "CarePlan/GetBehaviourCarePlanByPatientId";
        this.createDressingCarePlanURL = "CarePlan/CreateUpdateDressingCarePlan";
        this.getDressingCarePlanURL = "CarePlan/GetDressingCarePlanByPatientId";
        this.createSafetyCarePlanURL = "CarePlan/CreateUpdateSafetyCarePlan";
        this.getSafetyCarePlanURL = "CarePlan/GetSafetyCarePlanByPatientId";
        this.createBathingCarePlanURL = "CarePlan/CreateUpdateBathingCarePlan";
        this.getBathingCarePlanURL = "CarePlan/GetBathingCarePlanByPatientId";
        this.createNutritionCarePlanURL = "CarePlan/CreateUpdateNutritionHydrationCarePlan";
        this.getNutritionCarePlanURL = "CarePlan/GetNutritionHydrationCarePlanByPatientId";
        this.createHygieneCarePlanURL = "CarePlan/CreateUpdateHygieneCarePlan";
        this.getHygieneCarePlanURL = "CarePlan/GetHygieneCarePlanByPatientId";
        this.createSkinCarePlanURL = "CarePlan/CreateUpdateSkinCarePlan";
        this.getSkinCarePlanURL = "CarePlan/GetSkinCarePlanByPatientId";
        this.createMobilityCarePlanURL = "CarePlan/CreateUpdateMobilityCarePlan";
        this.getMobilityCarePlanURL = "CarePlan/GetMobilityCarePlanByPatientId";
        this.createTransferCarePlanURL = "CarePlan/CreateUpdateTransferCarePlan";
        this.getTransferCarePlanURL = "CarePlan/GetTransferCarePlanByPatientId";
        this.createToiletingCarePlanURL = "CarePlan/CreateUpdateToiletingCarePlan";
        this.getToiletingCarePlanURL = "CarePlan/GetToiletingCarePlanByPatientId";
        this.getShiftDetailsBiIdURL = "MasterShift/GetShiftWithShiftId";
        this.GetShiftsTimeBasedOnIdURL = "MasterShift/GetShiftsTimeBasedOnId";
        this.GetAllDueTimesURL = "MasterShift/GetAllDueTimes";
        this.createBladderCarePlanURL = "CarePlan/CreateUpdateBladderCarePlan";
        this.getBladderCarePlanURL = "CarePlan/GetBladderCarePlanByPatientId";
        this.createBowelCarePlanURL = "CarePlan/CreateUpdateBowelCarePlan";
        this.getBowelCarePlanURL = "CarePlan/GetBowelCarePlanByPatientId";
        this.importCCDAURL = "patients/ImportPatientCCDA";
        this.changeTaskStatusURL = "api/PatientAppointments/UpdateTaskStatus";
        // get relationship data
        this.getrelationshipDataURL = "PatientMedicalFamilyHistory/GetRelationshipList";
        this.getSubjectiveListListURL = "/Patients/GetSubjectiveList";
        this.getGetAssessmentListURL = "/Patients/GetAssessmentList";
        this.getRouteListListURL = "/PatientsMedication/GetMedicationRoute";
        this.getMedicationsListURL = "/PatientsMedication/GetMedicationFDbName";
        this.getDrugNameURL = "/PatientsMedication/GetDrugName";
        this.getMasterLanguages = "/Patients/GetLanguagesBySearch";
        this.getInventoryNameURL = "PatientsMedication/GetInvaentoryName";
        this.getProductsListURL = "/PatientsMedication/GetProductsFDbName";
        this.getReligionListURL = "PatientHistory/GetReligionName";
        this.getUnitListURL = "/PatientsMedication/GetMasterMedicationUnit";
        this.getReasonListURL = "/PatientsMedication/GetMedicationIndicationOfUse";
        this.getFrequencyListURL = "/PatientsMedication/GetMedicationFrequencyList";
        this.saveUpddateMasterInventory = "/MasterInventory/SaveUpdateMasterInventory";
        this.reorderInventory = "/MasterInventory/ReorderInventory";
        this.getMasterInventoryByIdURL = "/MasterInventory/GetSaveUpdateMasterInventoryById";
        this.GetInventoryDetailsByIdURL = "/MasterInventory/GetInventoryDetailsById";
        this.getAllInventoryList = "/MasterInventory/GetAllMasterInventoryList";
        this.GetAllConcentList = "ConcentPolicy/GetPatientConsent";
        this.getAllInventoryTrackList = "InventoryTrack/GetAllInventoryTrack";
        this.getAllReorderTrackList = "InventoryTrack/GetAllReorderTrack";
        this.deleteInventorymasterURL = "/MasterInventory/DeleteMasterInventoryById";
        this.deleteIncomingFaxURL = "/PatientReferral/DeleteIncomingFaxByFileName";
        this.saveIncomingFaxURL = "/PatientReferral/SaveIncomingFaxDocument";
        this.GetPatientNameURL = "/Patients/GetPatientName";
        this.saveUpdateStockOutUrl = "/InventoryTrack/SaveUpdateInventoryTrack";
        this.deleteInvantoryTrackURL = "InventoryTrack/DeleteInventoryTrackById";
        this.GetByInventoryTrackIdURL = "InventoryTrack/GetInventoryTrackById";
        this.GetEmrDetailsURL = "PatientsMedication/GetEMRPatientMedicationList";
        this.GetEmrtimeDetailsURL = "PatientsMedication/GetEMRPatientMedicationListByDueTime";
        this.updateEmrDetailsURL = "PatientsMedication/UpdateForMissedMedication";
        this.GetEmrDetailsURL1 = "PatientsMedication/UpdateForMissedMedication";
        this.getCurrentmedicationIdURL = "/Plan/GetCurrentmedicationById";
        this.GetDrugSideEffectBulk = "/Drugs/GetDrugSideEffectBulk";
        this.GetDrugDiseaseBulk = "/Drugs/GetDrugDiseaseBulk";
        this.GetMinMaxDoseBulk = "/Drugs/GetMinMaxDoseBulk";
        this.GetDrugIndicationBulk = "/Drugs/GetDrugIndicationBulk";
        this.GetDrugAllergyBulk = "/Drugs/GetDrugAllergyBulk";
        this.GetDrugGeriatricsPrecautionBulk = "/Drugs/DrugGeriatricsPrecautionBulk";
        this.GetDrugFoodFamilyEducationBulk = "/Drugs/DrugFoodFamilyEducationBulk";
        this.GetDrugDrugInterationBulk = "/Drugs/GetDrugInterationBulk";
        this.GetPatientDrugs = "/Drugs/GetPatientDrugHistory";
        this.GetDrugDuplicateTherapyBulk = "/Drugs/GetDrugDuplicateTherapyBulk";
        this.GetDrugDrugIntration = "/Drugs/GetDrugDrugIntration";
        this.getEMRPatientMedicationOrderChangeListURL = "PatientsMedication/GetEMRPatientMedicationOrderChangeList";
        this.discontinueCurrentmedicationURL = "PatientsMedication/DiscontinuedMedication";
        this.getllMasterProviderListURL = "PatientReferral/GetAllMasterProviderListForDropDown";
        this.getAllStaffListtURL = "PatientReferral/GetAllStaffListReferral";
        this.getMasterStatusListURL = "MealPlan/GetAllMasterStatusListForDropDown";
        this.getMasterMealCategoryListURL = "MealPlan/GetAllMasterMealCategoryForDropDown";
        this.getMasterMealItemListURL = "MealPlan/GetAllMasterMealItemForDropDown";
        this.getAllLocalResourcesMasterForDropDownURL = "Patients/GetAllLocalResourcesMasterForDropDown";
        this.getAllLocalResourcesMasterURL = "Patients/GetAllLocalResourcesMaster";
        this.getAllLocalResourcesMasterForFamlyPoratlURL = "Patients/GetAllLocalResourcesMasterForFamilyPortal";
        // private getMasterMealItemListURL =
        // "MealPlan/GetAllMasterMealItemForDropDown";
        this.getMasterTypeofFoodListURL = "MealPlan/GetAllMasterTypeofFoodForDropDown";
        this.getMasterFluidConsistencyListURL = "MealPlan/GetAllMasterFluidConsistencyForDropDown";
        this.getIncomingReferralListURL = "PatientReferral/GetAllIncomingReferral";
        this.GetFoodDiaryDetailsURL = "MealPlan/GetAllPatientFoodDiary";
        this.GetFoodDiaryDetailsByPatientIdURL = "MealPlan/GetAllPatientFoodDiaryByPatientId";
        this.getUnitsByLocationIdUrl = "MealPlan/GetUnitByLocationId";
        this.getStaffAndPatientByLocationUrl = "MealPlan/GetStaffAndPatientByLocation";
        this.setPassword = "PasswordSetup";
        this.getCareProviderByUnitIdURL = "Patients/GetPatientCareProviderList";
        this.getExternalCareProviderByUnitIdURL = "Patients/GetPatientExternalCareProviderList";
        this.getServiceFeedBackClientIdURL = "Patients/GetFollowUpAppointmentDetails";
        this.getMasterTemplatesURL = "MasterTemplates/GetMasterTemplates";
        this.getPatientLabTemplatesURL = "MasterTemplates/GetPatientLabTemplates";
        this.deletePatientsNotesByIdURL = "Patients/DeletePatientNotesData";
        this.GetInvestigationListURL = "InvestigationCenter/GetAllInvestigationCenterLists";
        this.GetTemplateByIdURL = "patient-encounter/GetPatientEncounterTemplateData";
        this.SaveTemplateDataURL = "patient-encounter/SaveEncounterTemplateData";
        this.getFilledTemplateFormURL = "patient-encounter/GetPatientLabTemplateData";
        // private deletePatientsNotesByIdURL = "Patients/DeletePatientNotesData";
        this.getLastVisitPatientsAppointmentsByIdURL = "Patients/GetLastVisitPatientsAppointments";
        this.getNextVisitPatientsAppointmentsByIdURL = "Patients/GetNextVisitPatientsAppointments";
        this.getInputOutChartUrl = "NutritionAndHydration/InputOutoutChartByPatientId";
        this.getInputOutChartTrendUrl = "NutritionAndHydration/InputOutputTrendGraph";
        this.fetchFaxDetailsUrl = "Patients/FetchFaxDetails";
        this.GetCrossProgressNoteUrl = "Patients/CrossOutProgressNote";
        this.PreviousTarIsCrossOutUrl = "TAR/PreviousTarIsCrossOut";
        this.IncidentReportCrossOutUrl = "IncidentReport/IncidentReportCrossOut";
        this.GetColorByIdUrl = "MasterColor/GetColorById";
        this.UpdateColorUrl = "MasterColor/UpdateColor";
        this.getPatientListURL = "PatientReporting/GetPatientList";
        this.getPatientReportDetailsURL = "PatientReporting/GetPatientReportDetails";
        //notifications
        this.deleteTaskNotificationURL = '/api/Notification/DeleteTaskNotifications';
        this.deleteStaffExpiredDocNotificationURL = '/api/Notification/DeleteStaffExpiredDocNotification';
        this.deleteResidentExpiredDocNotificationURL = 'api/Notification/DeleteResidentExpiredDocNotification';
        this.deleteURL = '/api/Notification/DeleteNotifications';
        this.deleteMissedMedNotificationURL = '/api/Notification/DeleteMissedMedicationNotifications';
        this.TurnOffNotificationListURL = "/api/Notification/TurnOffReadedNotification";
        this.DiscardADLTrackingToolByIdURL = "PointOfCare/DiscardADLTrackingToolById";
        this.DiscardActivityExerciseByIdURL = "ActivityExercise/DiscardActivityExerciseById";
        this.DiscardNutritionAndHydrationByIdURL = "NutritionAndHydration/DiscardNutritionAndHydrationById";
        this.DiscardPersonalHygieneByIdURL = "/PersonalHygiene/DiscardPersonalHygieneById";
        this.DiscardSaveAsDraftByIdURL = "SafetyAndSecurity/DiscardSaveAsDraftById";
        this.DiscardSleepRestAndComfortByIdURL = "SleepRestAndComfort/DiscardSleepRestAndComfortById";
        this.resendReferalByIdURL = "PatientReferral/ResendReferalById";
        this.saveUpdateDementiaUrl = "PatientQualityMesure/SaveUpdatePatientDementia";
        this.saveUpdateHeartFailUrl = "PatientQualityMesure/SaveUpdatePatientHeartFail";
        this.saveUpdateCOPDUrl = "PatientQualityMesure/SaveUpdatePatientCOPD";
        this.getDementiaByPatientIdUrl = "PatientQualityMesure/GetPatientLatestDemintia";
        this.getHeartFailByPatientIdUrl = "PatientQualityMesure/GetPatientLatestHeartFail";
        this.getCopdByPatientIdUrl = "PatientQualityMesure/GetPatientLatestCOPD";
        this.getCdssMessagesUrl = "CDSS/GetOxygenMessageCDS";
        this.checkIfExistUrl = "/CheckUserNameAlreadyExist?userName=";
        this.getFromByCategoryIdURL = "/FillablePDF/GetFormsByCategoryId";
        this.getFromByProviderIdURL = "/FillablePDF/GetFormsByProviderId";
        this.getImagesWithFieldURL = "/FillablePDF/GetPdfImagesFromForm";
        this.pdfResponseSubmitURL = "/PdfFormResponse/SubmitPdfForm";
        this.pdfResponseSubmitListingURL = "/PdfFormResponse/GetAllFormsWithCategoryId";
        this.ResubmitFormByIdURL = "PdfFormResponse/ResubmitFormById";
        this.reviewLabResultURL = "/PatientReferral/ReviewLabResult";
        this.reviewPrnUrl = "/PatientsMedication/ReviewPRNStatusOfMedication";
        this.deletePDFFormURL = "PdfFormResponse/DeletePDFFormById";
    }
    ClientsService.prototype.crossIncidentReport = function (reqmodel) {
        return this.commonService.post(this.IncidentReportCrossOutUrl, reqmodel
        // { id: id }
        );
    };
    ClientsService.prototype.crossPreviousTar = function (reqmodel) {
        return this.commonService.post(this.PreviousTarIsCrossOutUrl, reqmodel
        // { id: id }
        );
    };
    ClientsService.prototype.crossProgesNotes = function (reqmodel) {
        return this.commonService.post(this.GetCrossProgressNoteUrl, reqmodel
        // { patientsProgressNotesId: patientsProgressNotesId, patientID: patientId }
        );
    };
    ClientsService.prototype.fetchFaxDetails = function (fileName) {
        return this.commonService.getAll(this.fetchFaxDetailsUrl + "?fileName=" + fileName, {});
    };
    ClientsService.prototype.getMasterTemplates = function (investigationCenterId) {
        return this.commonService.getAll(this.getMasterTemplatesURL + "?investigationId=" + investigationCenterId, {});
    };
    ClientsService.prototype.getPatientLabTemplates = function (filterModel, patientId) {
        //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;
        return this.commonService.getAll(this.getPatientLabTemplatesURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&PatientId=" +
            patientId +
            "&SortColumn=" +
            filterModel.sortColumn +
            "&SortOrder=" +
            filterModel.sortOrder, {});
    };
    ClientsService.prototype.saveTemplateData = function (postData) {
        return this.commonService.post(this.SaveTemplateDataURL, postData);
    };
    ClientsService.prototype.getTemplateForm = function (enpunterId, templateId) {
        var queryParams = "?patientEncounterId=" + 0 + "&masterTemplateId=" + templateId;
        return this.commonService.getById(this.GetTemplateByIdURL + queryParams, {});
    };
    ClientsService.prototype.getFilledTemplateForm = function (templateId) {
        var queryParams = "?patientEncounterId=" + templateId;
        return this.commonService.getById(this.getFilledTemplateFormURL + queryParams, {});
    };
    ClientsService.prototype.create = function (data) {
        return this.commonService.post(this.createURL, data);
    };
    ClientsService.prototype.getInvestigationList = function (isLab, isInvestigationCenter) {
        return this.commonService.getById(this.GetInvestigationListURL +
            "?isLab=" +
            isLab +
            "&isImagingCenter=" +
            isInvestigationCenter, {});
    };
    ClientsService.prototype.getSkinAssessmentDroDownList = function () {
        return this.commonService.getAll(this.getRespiratorySkinAssessmentDropDownListURL, {});
    };
    ClientsService.prototype.deleteStockOut = function (id) {
        return this.commonService.patch(this.deleteInvantoryTrackURL + "?Id=" + id, {});
    };
    ClientsService.prototype.createPatientHistory = function (data) {
        return this.commonService.post(this.createPatientHistoryUrl, data);
    };
    ClientsService.prototype.createAdlTrackingTool = function (data) {
        return this.commonService.post(this.createUpdateAdlTrackingToolUrl, data);
    };
    ClientsService.prototype.updateAdlTrackingToolCrossOutReason = function (adlTrackingToolId, crossOutReasonId) {
        return this.commonService.post(this.updateAdlTrackingToolCrossOutReasonUrl + ("?adlTrackingToolId=" + adlTrackingToolId + "&crossOutReasonId=" + crossOutReasonId), '');
    };
    ClientsService.prototype.crossADLTrackingTool = function (reqmodel) {
        return this.commonService.post(this.ADLTrackingToolIsCrossURL, reqmodel);
    };
    ClientsService.prototype.createMoodAndBehaviourTrackingTool = function (data) {
        return this.commonService.post(this.SaveUpdateMoodAndBehaviorUrl, data);
    };
    ClientsService.prototype.updateMabTrackingToolCrossOutReason = function (mabTrackingToolId, crossOutReasonId) {
        return this.commonService.post(this.updateMabTrackingToolCrossOutReasonUrl + ("?mabTrackingToolId=" + mabTrackingToolId + "&crossOutReasonId=" + crossOutReasonId), '');
    };
    ClientsService.prototype.crossBehaviorTrackingTool = function (reqmodel) {
        return this.commonService.post(this.BehaviorTrackingToolCrossURL, reqmodel
        //{ moodAndBehaviorId: moodAndBehaviorId }
        );
    };
    ClientsService.prototype.createsafetyAndSecurityNursingFlow = function (data) {
        return this.commonService.post(this.createsafetyAndSecurityNursingFlowUrl, data);
    };
    ClientsService.prototype.updateNurisngCareFlowCrossOutReason = function (safetyAndSecurityId, crossOutReasonId) {
        return this.commonService.post(this.updateNurisngCareFlowCrossOutReasonUrl + ("?safetyAndSecurityId=" + safetyAndSecurityId + "&crossOutReasonId=" + crossOutReasonId), '');
    };
    ClientsService.prototype.crossPreviousNursingCareFlow = function (reqmodel) {
        return this.commonService.post(this.PreviousNursingCareFlowIsCrossURL, reqmodel
        //{ sefetyAndSecurityId: sefetyAndSecurityId }
        );
    };
    ClientsService.prototype.createSleepAndComfortNursingFlow = function (data) {
        return this.commonService.post(this.createSleepAndComfortNursingFlowUrl, data);
    };
    ClientsService.prototype.createUpdateIncidentReport = function (data) {
        return this.commonService.post(this.createUpdateIncidentReportUrl, data);
    };
    ClientsService.prototype.updateIncidentCrossOutReason = function (incidentId, crossOutReasonId) {
        return this.commonService.post(this.updateIncidentCrossOutReasonUrl + ("?incidentId=" + incidentId + "&crossOutReasonId=" + crossOutReasonId), '');
    };
    ClientsService.prototype.createPersonalHygieneNursingFlow = function (data) {
        return this.commonService.post(this.createPersonalHygieneNursingFlowUrl, data);
    };
    ClientsService.prototype.createNutritaionAndHydrationNursingFlow = function (data) {
        return this.commonService.post(this.SaveUpdateNutritionAndHydrationURL, data);
    };
    ClientsService.prototype.createActivityExersiceNursingFlow = function (data) {
        return this.commonService.post(this.createActivityExersiceNursingFlowUrl, data);
    };
    ClientsService.prototype.createCreateESASRating = function (data) {
        return this.commonService.post(this.SaveUpdateESASRatingsUrl, data);
    };
    ClientsService.prototype.saveAsDraftESASRating = function (data) {
        return this.commonService.post(this.saveUpdateAsDraftUrl, data);
    };
    ClientsService.prototype.deleteAdlTrackingTool = function (id) {
        return this.commonService.patch(this.deleteAdlTrackingToolUrl + "?Id=" + id, {});
    };
    ClientsService.prototype.getCdssFallMessage = function (fallValue) {
        return this.commonService.getById(this.cdssFallMessage + "?fallValue=" + fallValue, {});
    };
    ClientsService.prototype.showCdssMessagePressureUlcer = function (value) {
        return this.commonService.getById(this.cdssPressureUlcerMessageURL + "?fallValue=" + value, {});
    };
    ClientsService.prototype.showCdssMessageBehavior = function (value) {
        return this.commonService.getById(this.cdssBehaviorMessageURL + "?fallValue=" + value, {});
    };
    ClientsService.prototype.showCdssMessageActivity = function (value) {
        return this.commonService.getById(this.cdssActivityMessageURL + "?fallValue=" + value, {});
    };
    ClientsService.prototype.showCdssMessageNewPain = function (value) {
        return this.commonService.getById(this.cdssNewPainMessageURL + "?fallValue=" + value, {});
    };
    ClientsService.prototype.showCdssMessageOngoingPain = function (value) {
        return this.commonService.getById(this.cdsOngoingPainMessageURL + "?fallValue=" + value, {});
    };
    ClientsService.prototype.showCdssMessagePressureUlcerOngoing = function (value) {
        return this.commonService.getById(this.cdssPressureUlcerOngoingMessageURL + "?fallValue=" + value, {});
    };
    ClientsService.prototype.getAdlTrackingToolByPatientId = function (id) {
        return this.commonService.getById(this.getAdlTrackingToolUrlByPatientId + "?patientId=" + id, {});
    };
    ClientsService.prototype.getStatusofPOCByPatientId = function (id) {
        return this.commonService.getById(this.getStatusOfPOCUrlByPatientId + "?patientId=" + id, {});
    };
    ClientsService.prototype.getMoodAndBehaviourToolByPatientId = function (id) {
        return this.commonService.getById(this.getMoodAndBehaviorByPatientIdUrl + "?patientId=" + id, {});
    };
    ClientsService.prototype.discardSaveAsDraftMoodBehaviourTool = function (id) {
        return this.commonService.getById(this.DiscardMoodAndBehaviorByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.discardSaveAsDraftADLTrackingTool = function (id) {
        return this.commonService.getById(this.DiscardADLTrackingToolByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.DiscardActivityExerciseById = function (id) {
        return this.commonService.getById(this.DiscardActivityExerciseByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.DiscardNutritionAndHydrationById = function (id) {
        return this.commonService.getById(this.DiscardNutritionAndHydrationByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.DiscardPersonalHygieneById = function (id) {
        return this.commonService.getById(this.DiscardPersonalHygieneByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.DiscardSaveAsDraftById = function (id) {
        return this.commonService.getById(this.DiscardSaveAsDraftByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.DiscardSleepRestAndComfortById = function (id) {
        return this.commonService.getById(this.DiscardSleepRestAndComfortByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.GetSafetyAndSecurityByPatientIdUrl = function (id) {
        return this.commonService.getById(this.getSafetyAndSecurityByPatientIdUrl + "?patientId=" + id, {});
    };
    ClientsService.prototype.GetSleepAndRestByPatientIdUrl = function (id) {
        return this.commonService.getById(this.getSleepAndRestByPatientIdUrl + "?patientId=" + id, {});
    };
    ClientsService.prototype.GetPersonalHygieneByPatientIdUrl = function (id) {
        return this.commonService.getById(this.getPersonalHygieneNursingCareFlowUrl + "?patientId=" + id, {});
    };
    ClientsService.prototype.GetNutritionAndHydrationByPatientIdUrl = function (id) {
        return this.commonService.getById(this.getNutritionAndHydrationNursingCareFlowUrl + "?patientId=" + id, {});
    };
    ClientsService.prototype.GetActivityAndExerciseByPatientId = function (id) {
        return this.commonService.getById(this.getActivityExersiceNursingFlowUrlByPatientId + "?patientId=" + id, {});
    };
    ClientsService.prototype.getEsasRatingByPatientId = function (id) {
        return this.commonService.getById(this.getESASRatingsUrlByPatientId + "?patientId=" + id, {});
    };
    ClientsService.prototype.getViewEsasRatingByPatientIdShiftIdLocationId = function (id, locationId, shiftId, unitId, fromDate, toDate, isDateSearch, ratingTime) {
        return this.commonService.getById(this.getViewESASRatingsUrlByPatientId +
            "?patientId=" +
            id +
            "&locationId=" +
            locationId +
            "&shiftId=" +
            shiftId +
            "&unitId=" +
            unitId +
            "&startDate=" +
            fromDate +
            "&endDate=" +
            toDate +
            "&isDateSearch=" +
            isDateSearch +
            "&ratingTime=" +
            ratingTime, {});
    };
    ClientsService.prototype.getEsasRatingByPatientIdShiftIdLocationId = function (id, locationId, 
    //shiftId: number,
    unitId, selectedTime) {
        return this.commonService.getById(this.getESASRatingsUrlByPatientId +
            "?patientId=" +
            id +
            "&locationId=" +
            locationId +
            "&unitId=" +
            unitId +
            "&ratingTime=" +
            selectedTime, {});
    };
    ClientsService.prototype.deleteOtherProblems = function (id) {
        return this.commonService.patch(this.DeleteESASRatingsByIdUrl + "?Id=" + id, {});
    };
    ClientsService.prototype.getPatientLocation = function (patientId) {
        return this.commonService.getById(this.getPatientLocationByPatientId + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.getPatientUnit = function (patientId) {
        return this.commonService.getById(this.getPatientUnitByPatientId + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.getMasterData = function (value) {
        if (value === void 0) { value = ""; }
        return this.commonService.post(this.getMasterDataByNameURL, {
            masterdata: value,
        });
    };
    ClientsService.prototype.CreateUpdateMasterInventory = function (model) {
        return this.commonService.getdatata(this.saveUpddateMasterInventory, model);
    };
    ClientsService.prototype.ReorderInventory = function (model) {
        return this.commonService.post(this.reorderInventory, model);
    };
    ClientsService.prototype.getMasterInventoryById = function (Id) {
        return this.commonService.getById(this.getMasterInventoryByIdURL + "?Id=" + Id, {});
    };
    ClientsService.prototype.GetInventoryDetailsById = function (Id) {
        return this.commonService.getById(this.GetInventoryDetailsByIdURL + "?Id=" + Id, {});
    };
    ClientsService.prototype.getMasterShiftData = function (LocationId) {
        return this.commonService.getAll(this.getMasterShiftDataURL + "?locationId=" + LocationId, {});
    };
    ClientsService.prototype.getMasterShiftDataByUnit = function (LocationId, shiftType) {
        return this.commonService.getAll(this.getMasterShiftDataByUnitURL +
            "?unitId=" +
            LocationId +
            "&shiftType=" +
            shiftType, {});
    };
    ClientsService.prototype.getStaffShiftDataByUnit = function (LocationId, shiftType, staffId) {
        return this.commonService.getAll(this.getStaffShiftDataByUnitURL +
            "?unitId=" +
            LocationId +
            "&shiftType=" +
            shiftType +
            "&staffId=" +
            staffId, {});
    };
    ClientsService.prototype.getMasterShiftDataByUnitIds = function (unitIds, shiftType) {
        return this.commonService.getAll(this.GetShiftByUnitIdDataURL +
            "?unitId=" +
            unitIds +
            "&shiftType=" +
            shiftType, {});
    };
    ClientsService.prototype.getStaffAvailabilityByLocation = function (shiftId) {
        return this.commonService.getById(this.getStaffAvailabilityByLocationURL + "?shiftId=" + shiftId, {});
    };
    ClientsService.prototype.getShiftDetailsByShiftId = function (shiftId) {
        return this.commonService.getById(this.getShiftDetailsBiIdURL + "?shiftId=" + shiftId, {});
    };
    ClientsService.prototype.GetShiftsTimeBasedOnId = function (masterShiftId) {
        return this.commonService.getById(this.GetShiftsTimeBasedOnIdURL + "?masterShiftId=" + masterShiftId, {});
    };
    ClientsService.prototype.GetAllDueTimes = function (shiftStartTime, shiftEndTime, unitId) {
        return this.commonService.getById(this.GetAllDueTimesURL + "?ShiftStartTime=" + shiftStartTime + "&ShiftEndTime=" + shiftEndTime + "&UnitId=" + unitId, {});
    };
    // getRespiratoryAssessmentDroDownList() {
    //     return this.commonService.post(this.getRespiratoryAssessmentDropDownListURL,'');
    // }  GetAllDueTimesURL
    ClientsService.prototype.getRespiratoryAssessmentDroDownList = function () {
        return this.commonService.getAll(this.getRespiratoryAssessmentDropDownListURL, {});
    };
    ClientsService.prototype.getAllStaffList = function () {
        return this.commonService.getAll(this.getStaffListURL, {});
    };
    ClientsService.prototype.getMasterSuppliesData = function (value) {
        if (value === void 0) { value = ""; }
        return this.commonService.post(this.getMasterSuppliesDataURL, {
            masterdata: value,
        });
    };
    ClientsService.prototype.getMasterSuppliesMedicalData = function (id) {
        return this.commonService.getById(this.getMasterSuppliesMedicalDataURL + "?id=" + id, {});
    };
    ClientsService.prototype.getMasterSuppliesMedicationsData = function (id) {
        return this.commonService.getById(this.getMasterSuppliesMedicationsDataURL + "?id=" + id, {});
    };
    ClientsService.prototype.getMasterSuppliesMedicationsListData = function (id) {
        return this.commonService.getById(this.getMasterSuppliesMedicationsListDataURL + "?id=" + id, {});
    };
    ClientsService.prototype.getMasterInventoryTypeData = function (value) {
        if (value === void 0) { value = ""; }
        return this.commonService.post(this.getMasterInventoryTypeDataURL, {
            masterdata: value,
        });
    };
    ClientsService.prototype.updateClientNavigations = function (id, userId) {
        if (userId === void 0) { userId = null; }
        this.commonService.updateClientNaviagations(id, userId);
    };
    ClientsService.prototype.getClientFamilyEducation = function (id) {
        return this.commonService.getById(this.getPatientFamilyEducation + "?patientId=" + id, {});
    };
    ClientsService.prototype.getClientById = function (id) {
        return this.commonService.getById(this.getClientByIdURL + "?patientId=" + id, {});
    };
    ClientsService.prototype.GetPatientsTagsList = function (patientId) {
        return this.commonService.getById(this.getPatientsTagsByIdURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.GetPatientsNotesList = function (patientId) {
        return this.commonService.getById(this.getPatientsNotesByIdURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.deletetPatientsNotes = function (id) {
        return this.commonService.patch(this.deletePatientsNotesByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.GetPatientCareProviderList = function (id, patientId) {
        return this.commonService.getById(
        // this.getCareProviderByUnitIdURL + "?unitId=" + id,"&isActive=" +
        // patientId
        this.getCareProviderByUnitIdURL +
            "?unitId=" +
            id +
            "&patientId=" +
            patientId, {});
    };
    ClientsService.prototype.GetAllLocalResourcesMaster = function (masterData) {
        return this.commonService.post(this.getAllLocalResourcesMasterURL, masterData);
    };
    ClientsService.prototype.GetAllLocalResourcesMasterForFamilyPortal = function (masterData) {
        return this.commonService.post(this.getAllLocalResourcesMasterForFamlyPoratlURL, masterData);
    };
    ClientsService.prototype.GetPatientExternalCareProviderList = function (id, patientId) {
        return this.commonService.getById(
        // this.getCareProviderByUnitIdURL + "?unitId=" + id,"&isActive=" +
        // patientId
        this.getExternalCareProviderByUnitIdURL +
            "?unitId=" +
            id +
            "&patientId=" +
            patientId, {});
    };
    ClientsService.prototype.getFollowUpDetailsByClientId = function (id) {
        return this.commonService.getById(this.getFollowUpByClientIdURL + "?patientId=" + id, {});
    };
    ClientsService.prototype.getLastVisitPatientsAppointmentsById = function (id, currentdate) {
        return this.commonService.getById(this.getLastVisitPatientsAppointmentsByIdURL +
            "?patientId=" +
            id +
            "&appoDate=" +
            currentdate, {});
    };
    ClientsService.prototype.getNextVisitPatientsAppointmentsById = function (id, currentdate) {
        return this.commonService.getById(this.getNextVisitPatientsAppointmentsByIdURL +
            "?patientId=" +
            id +
            "&appoDate=" +
            currentdate, {});
    };
    ClientsService.prototype.getServiceFeedBackByClientId = function (id) {
        return this.commonService.getById(this.getServiceFeedBackClientIdURL + "?patientId=" + id, {});
    };
    ClientsService.prototype.getPatientHistoryById = function (id) {
        return this.commonService.getById(this.getPatientHistoryInfoUrl + "?patientId=" + id, {});
    };
    ClientsService.prototype.getClientHeaderInfo = function (id) {
        return this.commonService.getById(this.getClientHeaderInfoURL + "?id=" + id, {});
    };
    ClientsService.prototype.getClientProfileInfo = function (id) {
        return this.commonService.getById(this.getClientProfileInfoURL + "?patientId=" + id, {});
    };
    ClientsService.prototype.getPatientProfileInfo = function (id) {
        return this.commonService.getById(this.getpatientdetailsurl + "?patientId=" + id, {});
    };
    ClientsService.prototype.updateClientStatus = function (patientId, isActive) {
        return this.commonService.patch(this.updateClientStatusURL +
            "?patientID=" +
            patientId +
            "&isActive=" +
            isActive, {});
    };
    ClientsService.prototype.updateUserStatus = function (patientId, isActive) {
        return this.commonService.patch(this.updateUserStatusURL + "/" + patientId + "/" + isActive, {});
    };
    ClientsService.prototype.getPatientCCDA = function (patientId) {
        return this.commonService.download(this.getPatientCCDAURL + "?id=" + patientId, {});
    };
    ClientsService.prototype.getPatientImmunization = function (patientId) {
        return this.commonService.getAll(this.getPatientImmunizationURL + "?id=" + patientId, {});
    };
    ClientsService.prototype.savePatientImmunization = function (req) {
        return this.commonService.post(this.savetPatientImmunizationURL, req);
    };
    ClientsService.prototype.deletePatientImmunization = function (id) {
        return this.commonService.getAll(this.deletePatientImmunizationURL + '?patientImmunizationId=' + id, {});
    };
    ClientsService.prototype.unblockPatient = function (userId) {
        return this.commonService.getById(this.unblockResident + "?userId=" + userId, {});
    };
    ClientsService.prototype.updatePatientPortalVisibility = function (patientId, userId, value) {
        var url = this.updatePatientPortalVisibilityURL +
            "?patientID=" +
            patientId +
            "&userID=" +
            userId +
            "&isPortalActive=" +
            value;
        return this.commonService.patch(url, {});
    };
    //Eligibility Enquiry Method
    ClientsService.prototype.getPayerByPatient = function (patientID, key) {
        return this.commonService.getAll(this.getPayerByPatientURL + "?PatientID=" + patientID + "&Key=" + key, {});
    };
    ClientsService.prototype.getEligibilityEnquiryServiceCodes = function () {
        return this.commonService.getAll(this.getEligibilityEnquiryServiceCodesURL, {});
    };
    ClientsService.prototype.download270 = function (patientId, patientInsuranceId, serviceTypeCodeIds, serviceCodeIds) {
        var url = this.download270URL +
            "?patientId=" +
            patientId +
            "&patientInsuranceId=" +
            patientInsuranceId +
            "&serviceTypeCodeIds=" +
            serviceTypeCodeIds +
            "&serviceCodeIds=" +
            serviceCodeIds;
        return this.commonService.download(url, {});
    };
    //Guardian Method  -- Remove all if not needed
    ClientsService.prototype.createGuardian = function (data) {
        return this.commonService.post(this.createGuardianURL, data);
    };
    ClientsService.prototype.getGuardianList = function (clientId, pageNumber, pageSize) {
        var url = this.getGuardianListURL +
            "?pageNumber=" +
            pageNumber +
            "&pageSize=" +
            pageSize +
            "&PatientId=" +
            clientId;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getGuardianById = function (id) {
        return this.commonService.getById(this.getGuardianByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteGuardian = function (id) {
        return this.commonService.post(this.deleteGuardianURL + "?id=" + id, {});
    };
    //Address Method  -- Remove all if not needed
    ClientsService.prototype.getPatientAddressesAndPhoneNumbers = function (clientId) {
        return this.commonService.getById(this.getAddressAndPhoneNumbersURL + "?patientId=" + clientId, {});
    };
    //Patient Inurance
    ClientsService.prototype.getPatientInsurance = function (clientId) {
        return this.commonService.getById(this.getPatientInsurances + "?patientId=" + clientId, {});
    };
    //Medication Method
    ClientsService.prototype.CreateUpdateNurseOrder = function (data) {
        return this.commonService.post(this.createUpdateNurseOrderURL, data);
    };
    ClientsService.prototype.savePatientAddressesAndPhoneNumbers = function (data) {
        return this.commonService.post(this.saveAddressAndPhoneNumbersURL, data);
    };
    // social history
    ClientsService.prototype.getPatientSocialHistory = function (patientId) {
        return this.commonService.getById(this.getPatientSocialHistoryURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createSocialHistory = function (data) {
        return this.commonService.post(this.savePatientSocialHistoryURL, data);
    };
    //Diagnosis
    ClientsService.prototype.getDiagnosisList = function (clientId) {
        // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
        return this.commonService.getAll(this.getDiagnosisListURL + "?PatientId=" + clientId, {});
    };
    ClientsService.prototype.getDiagnosisById = function (id) {
        return this.commonService.getById(this.getDiagnosisByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.createDiagnosis = function (data) {
        return this.commonService.post(this.createDiagnosisURL, data);
    };
    ClientsService.prototype.deleteDiagnosis = function (id) {
        return this.commonService.patch(this.deleteDiagnosisURL + "?id=" + id, {});
    };
    ClientsService.prototype.savePatientInsurance = function (data) {
        return this.commonService.post(this.savePatientInsurances, data);
    };
    //Patient Custom Label
    ClientsService.prototype.getPatientCustomLabel = function (clientId) {
        return this.commonService.getById(this.getPatientCustomLabels + "?patientId=" + clientId, {});
    };
    ClientsService.prototype.savePatientCustomLabel = function (data) {
        return this.commonService.post(this.savePatientCustomLabels, data);
    };
    //Patient Medical Family History
    ClientsService.prototype.getPatientMedicalFamilyHistoryList = function (clientId) {
        return this.commonService.getAll(this.getPatientMedicalFamilyHistoryListURL + "?patientID=" + clientId, {});
    };
    //Patient Surgical History
    ClientsService.prototype.getPatientSurgicalHistoryList = function (filterModel) {
        return this.commonService.post(this.getAllPatientSurgicalHistoryListURL, filterModel);
    };
    //Patient Hospitilization History
    ClientsService.prototype.getPatientHospitilizationHistoryList = function (filterModel) {
        return this.commonService.post(this.GetAllPatientHospitalizationHistoryData, filterModel);
    };
    ClientsService.prototype.getPatientMedicalFamilyHistoryById = function (id) {
        return this.commonService.getById(this.getPatientMedicalFamilyHistoryListURL + "?id=" + id, {});
    };
    ClientsService.prototype.getPatientSurgicalHistoryHistoryById = function (id) {
        return this.commonService.getById(this.getPatientSurgicalHistoryByIdListURL + "?id=" + id, {});
    };
    ClientsService.prototype.getPatientHospitilizationHistoryById = function (id) {
        return this.commonService.getById(this.getPatientHospitilizationHistoryListURL + "?Id=" + id, {});
    };
    ClientsService.prototype.deletePatientMedicalFamilyHistory = function (id) {
        return this.commonService.patch(this.deletePatientMedicalFamilyHistoryURL + "?id=" + id, {});
    };
    ClientsService.prototype.deletePatientSurgicalHistory = function (id) {
        return this.commonService.patch(this.deletePatientSurgicalHistoryByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.deletePatientHospitilizationHistory = function (id) {
        return this.commonService.patch(this.DeletePatientHospitalizationHistoryData + "?id=" + id, {});
    };
    ClientsService.prototype.savePatientFamilyHistoryData = function (data) {
        return this.commonService.post(this.savePatientFamilyHistoryDataURL, data);
    };
    ClientsService.prototype.savePatientSurgicalHistoryData = function (data) {
        return this.commonService.post(this.savePatientSurgicalHistoryDataURL, data);
    };
    ClientsService.prototype.savePatientHospitilizationHistoryData = function (data) {
        return this.commonService.post(this.SavePatientHospitalizationHistoryData, data);
    };
    ClientsService.prototype.downloadFile = function (blob, filetype, filename) {
        var newBlob = new Blob([blob], { type: filetype });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        var data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data);
        }, 100);
    };
    ClientsService.prototype.openDCMFile = function (blob, filetype, filename) {
        var newBlob = new Blob([blob], { type: filetype });
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
        }
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        var data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(data);
        }, 100);
        return data;
    };
    //Immunization Method
    ClientsService.prototype.createImmunization = function (data) {
        return this.commonService.post(this.createImmunizationURL, data);
    };
    ClientsService.prototype.getImmunizationList = function (clientId) {
        var url = this.getImmunizationListURL + "?patientId=" + clientId;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getImmunizationById = function (id) {
        return this.commonService.getById(this.getImmunizationByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteImmunization = function (id) {
        return this.commonService.patch(this.deleteImmunizationURL + "?id=" + id, {});
    };
    ClientsService.prototype.getMasterDataDropdwon = function (value) {
        if (value === void 0) { value = ""; }
        return this.commonService.post(this.getMasterData, { masterdata: value });
    };
    //Medication Method
    ClientsService.prototype.createMedication = function (data) {
        return this.commonService.post(this.createMedicationURL, data);
    };
    //Medication Method
    ClientsService.prototype.createcreateUpdateERX = function (data) {
        return this.commonService.post(this.createUpdateERXURL, data);
    };
    //Medication Method
    ClientsService.prototype.createHerbalSupplement = function (data) {
        return this.commonService.post(this.createSaveHerbalSupplementURL, data);
    };
    //Medication Method
    ClientsService.prototype.createOverCounterMedication = function (data) {
        return this.commonService.post(this.createSaveOverCounterMedicationURL, data);
    };
    ClientsService.prototype.getMedicationList = function (clientId) {
        var url = this.getMedicationListURL + "?patientId=" + clientId;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getMedicationListNames = function (clientId) {
        var url = this.GetMedicationNamesListURL + "?patientId=" + clientId;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getMedicationByPatientId = function (clientId) {
        var url = this.getPatientMedicationListURL + "?patientId=" + clientId;
        return this.commonService.getAll(url, {});
    };
    // getMedicationListByPatientId(filterModel) {
    //   let url = this.getMedicationListbyPatientIdURL + "?patientId=" + clientId;
    //   return this.commonService.getAll(url, {});
    // }
    //Patient Surgical History
    ClientsService.prototype.getMedicationListByPatientId = function (filterModel) {
        return this.commonService.post(this.getMedicationListbyPatientIdURL, filterModel);
    };
    ClientsService.prototype.getTreatmentTimeline = function (filterModel) {
        var url = this.getTreatmentTimeLineURL;
        return this.commonService.post(url, filterModel);
    };
    ClientsService.prototype.getDetails = function () {
        var url = "https://api.1up.health/user-management/v1/user";
        var data = {
            app_user_id: "jitendra.chilate@smartdatainc.net",
            client_id: "391583ccdb48469da163bfbf88e2ef46",
            client_secret: "eQQfhGSYufylgcs3TiYpl1YRkfFFgWuy",
        };
        return this.commonService.getdatata(url, data);
    };
    ClientsService.prototype.getOverCounterMedicationList = function (clientId) {
        var url = this.getOverCounterMedicationURL + "?patientId=" + clientId;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getMedicationNameList = function (searchText) {
        // http://108.168.203.227/HC_Patient_Merging/Patients/GetPatients
        var queryParams = "?searchKey=" + searchText;
        return this.commonService.getAll(this.getMedicationNameListURL + queryParams, {});
    };
    ClientsService.prototype.getLevelofEducationList = function (searchText) {
        var queryParams = "?SearchKey=" + searchText;
        return this.commonService.getAll(this.getLevelOfEducationListURL + queryParams, {});
    };
    ClientsService.prototype.getHerbalSupplementList = function (clientId) {
        var url = this.getHerbalSupplementURL + "?patientId=" + clientId;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getmedicationById = function (id) {
        return this.commonService.getById(this.getMedicationByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteMedication = function (id) {
        return this.commonService.patch(this.deleteMedicationURL + "?id=" + id, {});
    };
    ClientsService.prototype.getherbalmedicationById = function (id) {
        return this.commonService.getById(this.getherbalMedicationByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.getovermedicationById = function (id) {
        return this.commonService.getById(this.getoverMedicationByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteherbal = function (id) {
        return this.commonService.patch(this.deleteherbalMedicationURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteover = function (id) {
        return this.commonService.patch(this.deleteoverMedicationURL + "?id=" + id, {});
    };
    //Vitals Method
    ClientsService.prototype.createVital = function (data) {
        return this.commonService.post(this.createVitalURL, data);
    };
    //Vitals Method
    ClientsService.prototype.createPatientServiceFeedBack = function (data) {
        return this.commonService.post(this.createPatientServiceFeedBackURL, data);
    };
    ClientsService.prototype.createCommunicationCarePlan = function (data) {
        return this.commonService.post(this.createCommunocationCarePlanURL, data);
    };
    ClientsService.prototype.getCommunicationCarePlan = function (patientId) {
        return this.commonService.getAll(this.getCommunocationCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.getAdlTrackingTool = function (patientPreferenceFilterModel) {
        return this.commonService.post(this.getAdlTrackingToolUrl, patientPreferenceFilterModel);
    };
    ClientsService.prototype.getInputOutputChart = function (patientId, date) {
        return this.commonService.getById(this.getInputOutChartUrl + "?patientId=" + patientId + "&Date=" + date, {});
    };
    ClientsService.prototype.getInputOutputChartTrend = function (patientId, toDate, fromDate, isDateSearch) {
        return this.commonService.getById(this.getInputOutChartTrendUrl +
            "?patientId=" +
            patientId +
            "&toDate=" +
            toDate +
            "&fromDate=" +
            fromDate +
            "&isDateSearch=" +
            isDateSearch, {});
    };
    ClientsService.prototype.getEsasRatingGraphData = function (id, locationId, shiftId) {
        return this.commonService.getById(this.GetViewESASRatingsByPatientId +
            "?patientId=" +
            id +
            "&locationId=" +
            locationId +
            "&shiftId=" +
            shiftId, {});
    };
    ClientsService.prototype.getEsasRatingCurrentDayGraphData = function (monthFilerModel) {
        return this.commonService.post(this.getESASRatingCurrentDayGraphDataUrl, monthFilerModel);
    };
    ClientsService.prototype.getVitalList1 = function (clientId, filterModel) {
        var url = this.getVitalListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&PatientId=" +
            clientId +
            "&SortColumn=" +
            filterModel.sortColumn +
            "&SortOrder=" +
            filterModel.sortOrder;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getLabDocument = function (filterModel) {
        return this.commonService.post(this.getLabDocumentURL, filterModel);
    };
    ClientsService.prototype.getRadiologyReports = function (filterModel) {
        return this.commonService.post(this.getRadiologyReportURL, filterModel);
    };
    ClientsService.prototype.getRadiologyDicoms = function (filterModel) {
        return this.commonService.post(this.getRadiologyDicomURL, filterModel);
    };
    ClientsService.prototype.getCardiacDocuments = function (filterModel) {
        return this.commonService.post(this.getCardiacDocumentURL, filterModel);
    };
    ClientsService.prototype.getLabsResult = function (filterModel) {
        return this.commonService.post(this.getLabsResultDocumentURL, filterModel);
    };
    ClientsService.prototype.getPathologyDocuments = function (filterModel) {
        return this.commonService.post(this.getPathologyDocumentURL, filterModel);
    };
    ClientsService.prototype.getOtherDocuments = function (filterModel) {
        return this.commonService.post(this.getOtherDocumentURL, filterModel);
    };
    ClientsService.prototype.deletePatientRadiologyReportDocuments = function (id) {
        return this.commonService.patch(this.deleteRadiologyDocumentURl + "?id=" + id, {});
    };
    ClientsService.prototype.deletePatientRadiologyDicoms = function (id) {
        return this.commonService.patch(this.deleteRadiologyDicomURl + "?id=" + id, {});
    };
    ClientsService.prototype.deletePatientPathologyDocuments = function (id) {
        return this.commonService.patch(this.deletePathologyDocumentURl + "?id=" + id, {});
    };
    ClientsService.prototype.deletePatientCardiacDocuments = function (id) {
        return this.commonService.patch(this.deleteCardiacDocumentURl + "?id=" + id, {});
    };
    ClientsService.prototype.deletePatientLabDocuments = function (id) {
        return this.commonService.patch(this.deletePatientLabDocumentURl + "?id=" + id, {});
    };
    ClientsService.prototype.deletePatientOtherDocuments = function (id) {
        return this.commonService.patch(this.deletePatientOtherDocumentURl + "?id=" + id, {});
    };
    ClientsService.prototype.getVitalList = function (filterModel) {
        //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;
        return this.commonService.post(this.getVitalListURL, filterModel);
    };
    ClientsService.prototype.getVitalGraphData = function (filterModel) {
        //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;
        return this.commonService.post(this.getVitalGraphURL, filterModel);
    };
    ClientsService.prototype.getFunctionalGraphData = function (filterModel) {
        //let url = this.getVitalListURL + "?pageNumber=" + filterModel.pageNumber + "&pageSize=" + filterModel.pageSize + "&PatientId=" + clientId + "&SortColumn=" + filterModel.sortColumn + "&SortOrder=" + filterModel.sortOrder;
        return this.commonService.post(this.getCareSummaryGraphURL, filterModel);
    };
    ClientsService.prototype.getVitalById = function (id) {
        return this.commonService.post(this.getVitalByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteVital = function (id) {
        return this.commonService.patch(this.deleteVitalURL + "?id=" + id, {});
    };
    ClientsService.prototype.getLatestVitalById = function (id) {
        return this.commonService.getById(this.getLatestVitalByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.getShiftAvailablityById = function (id) {
        return this.commonService.getById(this.getshiftbyId + "?shiftId=" + id, {});
    };
    ClientsService.prototype.getCarePlanGoalById = function (filterModel) {
        return this.commonService.post(this.getCarePlanGoalByIdURL, filterModel);
    };
    ClientsService.prototype.getCarePlanGoalByGoalId = function (id) {
        return this.commonService.getById(this.getCarePlanGoalByGoalIdURL + "?id=" + id, {});
    };
    //Allergy Method
    ClientsService.prototype.createAllergy = function (data) {
        return this.commonService.post(this.createAllergyURL, data);
    };
    //Allergy Method
    ClientsService.prototype.createCheckAllergy = function (data) {
        return this.commonService.post(this.createCheckAllergyURL, data);
    };
    ClientsService.prototype.createInventory = function (data) {
        return this.commonService.post(this.createInventoryURL, data);
    };
    ClientsService.prototype.getAllergyList = function (clientId, filterModel) {
        var url = this.getAllergyListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&PatientId=" +
            clientId +
            "&SortColumn=" +
            filterModel.sortColumn +
            "&SortOrder=" +
            filterModel.sortOrder;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getAllergyCheckList = function (clientId, type) {
        var url = this.getAllergyCheckListURL + "?PatientID=" + clientId + "&Type=" + type;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getAllPatientFamilyHistoryList = function (clientId, filterModel) {
        var url = this.getAllPatientFamilyHistoryURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&PatientId=" +
            clientId +
            "&SortColumn=" +
            filterModel.sortColumn +
            "&SortOrder=" +
            filterModel.sortOrder;
        return this.commonService.getAll(url, {});
    };
    // getAllergyById(id: number) {
    //     return this.commonService.getById(this.getAllergyByIdURL + "?id=" + id, {});
    // }
    // Personal Inventory
    ClientsService.prototype.createPersonalInventory = function (data) {
        return this.commonService.post(this.createPersonalnventoryURL, data);
    };
    ClientsService.prototype.createUnitMaster = function (data) {
        return this.commonService.post(this.createUnitMasterURL, data);
    };
    ClientsService.prototype.createLabResult = function (data) {
        return this.commonService.post(this.createLabResultURL, data);
    };
    ClientsService.prototype.createDocumentResult = function (data) {
        return this.commonService.post(this.createDocumentResultURL, data);
    };
    ClientsService.prototype.createReferralResult = function (data) {
        return this.commonService.post(this.createReferralResultURL, data);
    };
    ClientsService.prototype.createLocalResourcesMaster = function (data) {
        return this.commonService.post(this.createLocalResourcesMasterURL, data);
    };
    ClientsService.prototype.createCareCoordinatorMaster = function (data) {
        return this.commonService.post(this.createCareCoordinatorMasterURL, data);
    };
    ClientsService.prototype.createPatientCareProvider = function (data) {
        return this.commonService.post(this.createPatientCareProviderURL, data);
    };
    ClientsService.prototype.createPatientTag = function (data) {
        return this.commonService.post(this.createPatientTagURL, data);
    };
    ClientsService.prototype.createManageTags = function (data) {
        return this.commonService.post(this.createManageTagsURL, data);
    };
    ClientsService.prototype.createPatientNotes = function (data) {
        return this.commonService.post(this.createPatientNotesURL, data);
    };
    ClientsService.prototype.createPatientAppointment = function (data) {
        return this.commonService.post(this.createPatientAppointmentURL, data);
    };
    ClientsService.prototype.getpersonalinventory = function (inventoryFilterModel) {
        var url = this.getpersonalinventoryURL +
            "?pageNumber=" +
            inventoryFilterModel.pageNumber +
            "&pageSize=" +
            inventoryFilterModel.pageSize +
            "&sortColumn=" +
            inventoryFilterModel.sortColumn +
            "&sortOrder=" +
            inventoryFilterModel.sortOrder +
            "&searchKey=" +
            inventoryFilterModel.searchText +
            "&patientId=" +
            inventoryFilterModel.patientId;
        return this.commonService.getAll(url, {}, false);
    };
    ClientsService.prototype.getPersonalInventoryById = function (id) {
        return this.commonService.getById(this.getPersonalInventoryByIdURL + "?PersonalInventoryId=" + id, {});
    };
    ClientsService.prototype.deleteinventory = function (id) {
        return this.commonService.patch(this.deleteInventoryURL + "?PersonalInventoryId=" + id, {});
    };
    ClientsService.prototype.deleteCarePlanGoalByGoalId = function (id) {
        return this.commonService.patch(this.deleteCarePlanGoalByGoalIdURL + "?CarePlanGoalId=" + id, {});
    };
    // Patient Preference
    ClientsService.prototype.createPatientPreference = function (data) {
        return this.commonService.post(this.savePatientConsentsPreferencesURL, data);
    };
    ClientsService.prototype.getPatientPreferenceList = function (patientPreferenceFilterModel) {
        var url = this.getPatientConsentsPreferencesURL +
            "?pageNumber=" +
            patientPreferenceFilterModel.pageNumber +
            "&pageSize=" +
            patientPreferenceFilterModel.pageSize +
            "&sortColumn=" +
            patientPreferenceFilterModel.sortColumn +
            "&sortOrder=" +
            patientPreferenceFilterModel.sortOrder +
            "&searchKey=" +
            patientPreferenceFilterModel.searchText +
            "&patientId=" +
            patientPreferenceFilterModel.patientId;
        return this.commonService.getAll(url, {}, false);
        // return this.commonService.post(this.getPatientConsentsPreferencesURL, patientPreferenceFilterModel);
    };
    ClientsService.prototype.getPatientServiceFeedBackList = function (patientPreferenceFilterModel) {
        var url = this.getPatientServiceFeedBackURL +
            "?pageNumber=" +
            patientPreferenceFilterModel.pageNumber +
            "&pageSize=" +
            patientPreferenceFilterModel.pageSize +
            "&sortColumn=" +
            patientPreferenceFilterModel.sortColumn +
            "&sortOrder=" +
            patientPreferenceFilterModel.sortOrder +
            "&searchKey=" +
            patientPreferenceFilterModel.searchText +
            "&patientId=" +
            patientPreferenceFilterModel.patientId;
        return this.commonService.getAll(url, {}, false);
        // return this.commonService.post(this.getPatientConsentsPreferencesURL, patientPreferenceFilterModel);
    };
    ClientsService.prototype.deletePatientPreference = function (id) {
        return this.commonService.patch(this.deletePatientConsentsPreferencesURL +
            "?PatientConsentsPreferencesID=" +
            id, {});
    };
    ClientsService.prototype.getPatientPreference = function (id) {
        return this.commonService.getById(this.getPatientConsentsPreferencesByIdURL +
            "?PatientConsentsPreferencesID=" +
            id, {});
    };
    ClientsService.prototype.updatePatientPreferenceStatus = function (id, status) {
        console.log("from call" + status);
        return this.commonService.patch(this.updatePatientPreferenceStatusURL +
            "?PatientConsentsPreferencesID=" +
            id +
            "&isActive=" +
            status, {});
    };
    ClientsService.prototype.deleteAllergy = function (id) {
        return this.commonService.patch(this.deleteAllergyURL + "?id=" + id, {});
    };
    ClientsService.prototype.getAllergyById = function (id) {
        return this.commonService.getById(this.getAllergyByIdURL + "?id=" + id, {});
    };
    //authorization Methods
    ClientsService.prototype.getAllAuthorization = function (clientId, filterModel) {
        var url = this.getAllAuthorizationsForPatientURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&patientId=" +
            clientId +
            "&SortColumn=" +
            filterModel.sortColumn +
            "&SortOrder=" +
            filterModel.sortOrder +
            "&authType=" +
            filterModel.authType;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getAuthorizationById = function (authorizationId) {
        return this.commonService.getById(this.getAuthorizationByIdURL + "?authorizationId=" + authorizationId, {});
    };
    ClientsService.prototype.deleteAuthorization = function (id) {
        return this.commonService.patch(this.deleteAuthorizationURL + "?id=" + id, {});
    };
    ClientsService.prototype.getPatientPayerServiceCodesAndModifiers = function (clientId, payerId, patientInsuranceId) {
        var url = this.getPatientPayerServiceCodesAndModifiersURL +
            "?patientId=" +
            clientId +
            "&PayerPreference=" +
            "Primary" +
            "&date=" +
            new Date() +
            "&payerId=" +
            payerId +
            "&patientInsuranceId=" +
            patientInsuranceId;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.createAuthorization = function (data) {
        return this.commonService.post(this.createAuthorizationURL, data);
    };
    //client encounter Methods
    ClientsService.prototype.getClientEncounters = function (filterModel, clientId, appointmentType, staffName, status, fromDate, toDate) {
        var url = this.getPatientEncountersListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&patientId=" +
            clientId +
            "&SortColumn=" +
            filterModel.sortColumn +
            "&SortOrder=" +
            filterModel.sortOrder;
        if (appointmentType && appointmentType > 0)
            url = url + "&appointmentType=" + appointmentType;
        if (staffName && staffName != "")
            url = url + "&staffName=" + staffName;
        if (status && status != "")
            url = url + "&status=" + status;
        if (fromDate && fromDate != "")
            url = url + "&fromDate=" + fromDate;
        if (toDate && toDate != "")
            url = url + "&toDate=" + toDate;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getClaimsForClientLegder = function (clientId, filterModel) {
        var url = this.getClaimsForLedgerURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&patientId=" +
            clientId +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getClaimServiceLinesForPatient = function (claimId) {
        return this.commonService.getById(this.getClaimServiceLineForPatientLedgerURL + "?claimId=" + claimId, {});
    };
    ClientsService.prototype.getPaymentDetailById = function (paymentDetailId) {
        return this.commonService.getById(this.getPaymentDetailByIdURL + "?paymentDetailId=" + paymentDetailId, {});
    };
    ClientsService.prototype.getPatientGuarantor = function (patientId) {
        return this.commonService.getById(this.getPatientGuarantorURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.saveServiceLinePayment = function (paymentModel) {
        return this.commonService.post(this.saveServiceLinePaymentURL, paymentModel);
    };
    ClientsService.prototype.deleteServiceLinePaymentDetail = function (id) {
        return this.commonService.patch(this.deleteServiceLinePaymentURL + "/" + id, {});
    };
    ClientsService.prototype.getUserDocuments = function (userId, from, toDate) {
        return this.commonService.getAll(this.getUserDocumentsURL +
            "?userId=" +
            userId +
            "&from=" +
            from +
            "&to=" +
            toDate, {});
    };
    ClientsService.prototype.getUserCareDocuments = function (userId, pageNumber, pageSize, searchText) {
        return this.commonService.getAll(this.getUserCarePlanDocumentsURL +
            "?userId=" +
            userId +
            "&pageNumber=" +
            pageNumber +
            "&pageSize=" +
            pageSize +
            "&searchText=" +
            searchText, {});
    };
    ClientsService.prototype.getWoundImages = function (userId, from, toDate) {
        return this.commonService.getAll(this.getWoundImgURL +
            "?userId=" +
            userId +
            "&from=" +
            from +
            "&to=" +
            toDate, {});
    };
    ClientsService.prototype.getUserByLocation = function (locationId) {
        var url = this.getUserByLocationURL +
            "?locationIds=" +
            locationId +
            "&permissionKey=SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES&isActiveCheckRequired=YES";
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getUserDocument = function (id) {
        return this.commonService.download(this.getUserDocumentURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteUserDocument = function (id) {
        return this.commonService.patch(this.deleteUserDocumentURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteWoundImage = function (id) {
        return this.commonService.patch(this.deleteWoundImg + "?id=" + id, {});
    };
    ClientsService.prototype.downloadWoundImage = function (id) {
        return this.commonService.download(this.downloadWoundImg + "?id=" + id, {});
    };
    ClientsService.prototype.uploadUserDocuments = function (data) {
        return this.commonService.post(this.uploadUserDocumentURL, data);
    };
    ClientsService.prototype.uploadPatientResultLabDocuments = function (data) {
        return this.commonService.post(this.patientResultLabDocumentURL, data);
    };
    ClientsService.prototype.uploadPatientResultRadiologyDicom = function (data) {
        return this.commonService.post(this.patientResultRadiologyDicomURL, data);
    };
    ClientsService.prototype.uploadPatientResultRadiologyReports = function (data) {
        return this.commonService.post(this.PatientResultRadiologyReportsURL, data);
    };
    ClientsService.prototype.uploadPatientCardiacReports = function (data) {
        return this.commonService.post(this.PatientCardiacReportsURL, data);
    };
    ClientsService.prototype.uploadPatientPathologyReports = function (data) {
        return this.commonService.post(this.PatientPathologyReportsURL, data);
    };
    ClientsService.prototype.uploadPatientOthersReports = function (data) {
        return this.commonService.post(this.PatientOthersReportsURL, data);
    };
    ClientsService.prototype.downloadPatientLabDocuments = function (id) {
        return this.commonService.download(this.getPatientLabDocumentURL + "?id=" + id, {});
    };
    ClientsService.prototype.downloadPatientRadiologyDicoms = function (id) {
        return this.commonService.download(this.getPatientRadiologyDicomURL + "?id=" + id, {});
    };
    ClientsService.prototype.downloadPatientRadiologyReports = function (id) {
        return this.commonService.download(this.getPatientRadiologyReportURL + "?id=" + id, {});
    };
    ClientsService.prototype.downloadPatientCardiacReports = function (id) {
        return this.commonService.download(this.getPatientCardiacReportURL + "?id=" + id, {});
    };
    ClientsService.prototype.downloadPatientPathologyReports = function (id) {
        return this.commonService.download(this.getPatientPathologyReportURL + "?id=" + id, {});
    };
    ClientsService.prototype.downloadPatientOtherReports = function (id) {
        return this.commonService.download(this.getPatientOtherReportURL + "?id=" + id, {});
    };
    ClientsService.prototype.getUserScreenActionPermissions = function (moduleName, screenName) {
        return this.commonService.getUserScreenActionPermissions(moduleName, screenName);
    };
    //chat
    ClientsService.prototype.getChatHistory = function (fromUserId, toUserId) {
        return this.commonService.getAll(this.getChatHistoryURL + "?FromUserId=" + fromUserId + "&ToUserId=" + toUserId, {});
    };
    ClientsService.prototype.importCCDA = function (data) {
        return this.commonService.post(this.importCCDAURL, { file: data });
    };
    ClientsService.prototype.getStateByCountryId = function (id) {
        return this.commonService.getById(this.getStateByCountryIdUrl + "?countryID=" + id, {});
    };
    ClientsService.prototype.getRoomsByUnitId = function (id, isRoomBooked, patientId) {
        return this.commonService.getById(this.getRoomNUmberByUnitId +
            "?unitId=" +
            id +
            "&isRoomBooked=" +
            isRoomBooked +
            "&patientId=" +
            patientId, {});
    };
    ClientsService.prototype.getRoomsByRoomId = function (id, isRoomBooked, patientId) {
        return this.commonService.getById(this.getRoomNUmberByRoomId +
            "?roomId=" +
            id +
            "&isRoomBooked=" +
            isRoomBooked +
            "&patientId=" +
            patientId, {});
    };
    ClientsService.prototype.createVitalAdd = function (data) {
        return this.commonService.post(this.createVitalURL, data);
    };
    ClientsService.prototype.createAddTRA = function (data) {
        return this.commonService.post(this.createTARUrl, data);
    };
    ClientsService.prototype.updateTarCrossOutReason = function (tarId, crossOutReasonId) {
        return this.commonService.post(this.updateTarCrossOutReasonUrl + ("?tarId=" + tarId + "&crossOutReasonId=" + crossOutReasonId), '');
    };
    ClientsService.prototype.createReferForm = function (data) {
        return this.commonService.post(this.createReferURL, data);
    };
    ClientsService.prototype.createIncomingReferForm = function (data) {
        return this.commonService.post(this.createIncomingReferURL, data);
    };
    ClientsService.prototype.createMealPlanForm = function (data) {
        return this.commonService.post(this.createMealPlanURL, data);
    };
    ClientsService.prototype.createMealCategoryForm = function (data) {
        return this.commonService.post(this.createMealCategoryURL, data);
    };
    ClientsService.prototype.createMealItemForm = function (data) {
        return this.commonService.post(this.createMealItemURL, data);
    };
    ClientsService.prototype.createPatientTransferForm = function (data) {
        return this.commonService.post(this.createPatientTransferURL, data);
    };
    ClientsService.prototype.getPatientTransferDocument = function (id) {
        return this.commonService.download(this.getPatientTransferDocumentURL + "?id=" + id, {});
    };
    ClientsService.prototype.createIncidentLogReport = function (data) {
        return this.commonService.post(this.createIncidentLogReportURL, data);
    };
    ClientsService.prototype.createCarePlanGoal = function (data) {
        return this.commonService.post(this.createCarePlanGoalURl, data);
    };
    ClientsService.prototype.createCardiovascularProcessNote = function (data) {
        return this.commonService.post(this.createVitalURL, data);
    };
    ClientsService.prototype.checkHealthCardPatient = function (phcNumber, patientId) {
        return this.commonService.getById(this.getCheckHealthCardPatientUrl +
            "?phcnumber=" +
            phcNumber +
            "&patientId=" +
            patientId, {});
    };
    ClientsService.prototype.getProgressNotes = function (clientId, IsDraft) {
        // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
        return this.commonService.getAll(this.getProgressNoteURL +
            "?PatientId=" +
            clientId +
            "&IsDraft=" +
            IsDraft, {});
    };
    ClientsService.prototype.getTAR = function (filterModel) {
        // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
        return this.commonService.getdatata(this.getTARUrl, filterModel);
    };
    ClientsService.prototype.getProgressNotes1 = function (filterModel) {
        return this.commonService.getdatata(this.getProgressNoteSearchURL, filterModel);
    };
    ClientsService.prototype.getTARSearch = function (filterModel) {
        return this.commonService.getdatata(this.getTARSearchURL, filterModel);
    };
    ClientsService.prototype.DeleteTARById = function (id) {
        return this.commonService.patch(this.deleteTarByIdtUrl + "?Id=" + id, {});
    };
    ClientsService.prototype.getTARById = function (id) {
        return this.commonService.getById(this.getTARByIdUrl +
            "?Id=" +
            id, {});
    };
    ClientsService.prototype.getAllIncidentReports = function (filterModel) {
        return this.commonService.getdatata(this.getAllIncidentReportURL, filterModel);
    };
    ClientsService.prototype.getIncidentReportById = function (id) {
        return this.commonService.getById(this.getIncidentReportByIdUrl +
            "?Id=" +
            id, {});
    };
    ClientsService.prototype.deleteIncident = function (id) {
        return this.commonService.patch(this.deleteIncidentReportUrl + "?Id=" + id, {});
    };
    ClientsService.prototype.getProgressNotesWithDate = function (filterModel) {
        return this.commonService.getdatata(this.getProgressNoteWithDateSearchURL, filterModel);
    };
    ClientsService.prototype.getTARwithDate = function (filterModel) {
        return this.commonService.getdatata(this.getTARbyDateURL, filterModel);
    };
    ClientsService.prototype.createNewNotes = function (data) {
        return this.commonService.post(this.postSaveProgressNoteURL, data);
    };
    ClientsService.prototype.updateProgressNoteCrossOutReason = function (progressNoteId, crossOutReasonId) {
        return this.commonService.post(this.updateProgressNoteCrossOutReasonUrl + ("?progressNoteId=" + progressNoteId + "&crossOutReasonId=" + crossOutReasonId), '');
    };
    ClientsService.prototype.createNewNotesSaveASDraft = function (data) {
        return this.commonService.post(this.postSaveNoteAsDraftProgressNoteURL, data);
    };
    ClientsService.prototype.getGastroIntestinalProgressNotes = function (ProgressNotesId, IsDraft) {
        return this.commonService.getAll(this.getGastroIntestinalProgressNoteURL +
            "?PatientsProgressNotesId=" +
            ProgressNotesId +
            "&IsDraft=" +
            IsDraft, {});
    };
    ClientsService.prototype.getGenitourinaryProgressNotes = function (ProgressNotesId, IsDraft) {
        return this.commonService.getAll(this.getGenitourinaryProgressNoteURL +
            "?PatientsProgressNotesId=" +
            ProgressNotesId +
            "&IsDraft=" +
            IsDraft, {});
    };
    ClientsService.prototype.getCardiovascularProgressNotes = function (ProgressNotesId, IsDraft) {
        // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
        return this.commonService.getAll(this.getCardiovascularProgressNoteURL +
            "?PatientsProgressNotesId=" +
            ProgressNotesId +
            "&IsDraft=" +
            IsDraft, {});
    };
    ClientsService.prototype.getRespiratoryProgressNotes = function (ProgressNotesId, IsDraft) {
        // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
        return this.commonService.getAll(this.getRespiratoryProgressNoteURL +
            "?PatientsProgressNotesId=" +
            ProgressNotesId +
            "&IsDraft=" +
            IsDraft, {});
    };
    ClientsService.prototype.getNeurologicalProgressNotes = function (ProgressNotesId, IsDraft) {
        // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
        return this.commonService.getAll(this.getNeurologicalProgressNotesURL +
            "?PatientsProgressNotesId=" +
            ProgressNotesId +
            "&IsDraft=" +
            IsDraft, {});
    };
    ClientsService.prototype.gettMusculokeletalProgressNotes = function (ProgressNotesId, IsDraft) {
        // let url=this.getDiagnosisListURL+"?PatientId="+clientId;
        return this.commonService.getAll(this.getMusculokeletalProgressNoteURL +
            "?PatientsProgressNotesId=" +
            ProgressNotesId +
            "&IsDraft=" +
            IsDraft, {});
    };
    ClientsService.prototype.getpatientAdminissionDroDownList = function () {
        return this.commonService.getAll(this.getpatientAdminissionDropDownListURL, {});
    };
    ClientsService.prototype.createpatientAdminission = function (data) {
        return this.commonService.post(this.createMDSAdmissionURL, data);
    };
    ClientsService.prototype.createAdtAdmission = function (data) {
        return this.commonService.post(this.createAdtAdmissionURL, data);
    };
    ClientsService.prototype.getAllAdmissionList = function (filterModel) {
        return this.commonService.post(this.getAllAdmissionListURL, filterModel);
    };
    ClientsService.prototype.getAdmissionById = function (Id) {
        return this.commonService.getById(this.getAdmissionByIdURL + "?id=" + Id, {});
    };
    ClientsService.prototype.deleteADTAdmissionDetails = function (id) {
        return this.commonService.patch(this.deleteAdmissionDetailsURL + "?id=" + id, {});
    };
    ClientsService.prototype.createAdtDischarge = function (data) {
        return this.commonService.post(this.createAdtDischargeURL, data);
    };
    ClientsService.prototype.getAllDischargeList = function (filterModel) {
        return this.commonService.post(this.getAllDischargeListURL, filterModel);
    };
    ClientsService.prototype.getDischargeDetailsById = function (Id) {
        return this.commonService.getById(this.getDischargeDetailsByIdURL + "?id=" + Id, {});
    };
    ClientsService.prototype.deleteADTDischargeDetails = function (id) {
        return this.commonService.patch(this.deleteDischargeDetailsURL + "?id=" + id, {});
    };
    ClientsService.prototype.getAllTransferList = function (filterModel) {
        return this.commonService.post(this.getAllTransferListURL, filterModel);
    };
    ClientsService.prototype.getTransferById = function (Id) {
        return this.commonService.getById(this.getTransferDetailsByIdURL + "?id=" + Id, {});
    };
    ClientsService.prototype.deleteADTTransferDetails = function (id) {
        return this.commonService.patch(this.deleteTransferDetailsURL + "?id=" + id, {});
    };
    ClientsService.prototype.getpatientAdminissionbyHeathCardNo = function (HealthCardNumber) {
        console.log("post hCard ", HealthCardNumber);
        return this.commonService.getAll(this.getHeathCardNumberMDSAdmissionURL +
            "?healthCardNumber=" +
            HealthCardNumber, {});
    };
    ClientsService.prototype.getUnitsByLocationId = function (id) {
        return this.commonService.getById(this.getUnitsByLocationIdUrl + "?locationId=" + id, {});
    };
    ClientsService.prototype.getStaffAndPatientByLocation = function (unitId, permissionKey) {
        if (permissionKey === void 0) { permissionKey = "SCHEDULING_LIST_VIEW_OTHERSTAFF_SCHEDULES"; }
        var queryParams = "?locationIds=0&permissionKey=" + permissionKey + "&isActiveCheckRequired=YES&unitId=" + unitId + "&isActiveCheckRequired=YES&shiftId=0";
        return this.commonService.getAll(this.getStaffAndPatientByLocationUrl + queryParams, {});
    };
    ClientsService.prototype.getpatientsearchRecord = function (searchRecord) {
        console.log("post hCard ", searchRecord);
        return this.commonService.getAll(this.getPatientRecordSearchURL + "?searchRecord=" + searchRecord, {});
    };
    ClientsService.prototype.getstaffsearchRecord = function (searchRecord) {
        console.log("post hCard ", searchRecord);
        return this.commonService.getAll(this.getStaffRecordSearchURL + "?searchRecord=" + searchRecord, {});
    };
    ClientsService.prototype.getdiagnosisBySearch = function (DiagnosisName) {
        console.log("post hCard ", DiagnosisName);
        return this.commonService.getAll(this.getDiagnosisListtURL + "?DiagnosisName=" + DiagnosisName, {});
    };
    ClientsService.prototype.getSurgeryBySearch = function (DiagnosisName) {
        return this.commonService.getAll(this.getSurgeryListURL + "?surgeryName=" + DiagnosisName, {});
    };
    ClientsService.prototype.createpatientdischarge = function (data) {
        return this.commonService.post(this.createpatientdischargeURL, data);
    };
    ClientsService.prototype.createpatienteRX = function (data) {
        return this.commonService.post(this.createRXURL, data);
    };
    ClientsService.prototype.createpatientsRX = function (data) {
        return this.commonService.post(this.creatpRXURL, data);
    };
    ClientsService.prototype.getpatientAdminissionbyHeathCardNobyData = function (HealthCardNumber) {
        console.log("post hCard ", HealthCardNumber);
        return this.commonService.getAll(this.getHeathCardNumberMDSAdmissionCardByDataURL +
            "?healthCardNumber=" +
            HealthCardNumber, {});
    };
    ClientsService.prototype.getStaffDetailByUsingName = function (HealthCardNumber) {
        console.log("post hCard ", HealthCardNumber);
        return this.commonService.getAll(this.getHeathCardNumberMDSAdmissionCardByDataURL +
            "?healthCardNumber=" +
            HealthCardNumber, {});
    };
    ClientsService.prototype.createCareSummary = function (data) {
        return this.commonService.post(this.createCarePlanURL, data);
    };
    ClientsService.prototype.getCareSummarybyPatientID = function (PatientID) {
        return this.commonService.getAll(this.getCareSummaryURL + "?patientId=" + PatientID, {});
    };
    ClientsService.prototype.GetTagsByPatientId = function (PatientID) {
        return this.commonService.getAll(this.getTagsByPatientId + "?patientID=" + PatientID, {});
    };
    ClientsService.prototype.createRoutineCarePlan = function (data) {
        return this.commonService.post(this.createRoutineCarePlanURL, data);
    };
    ClientsService.prototype.getRoutines = function (patientId) {
        return this.commonService.getAll(this.getRoutineCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createCognitiveStatusCarePlan = function (data) {
        return this.commonService.post(this.createCognitiveStatusCarePlanURL, data);
    };
    ClientsService.prototype.getMasterDataForTransferTo = function (masterData) {
        return this.commonService.post(this.getllMasterProviderListURL, masterData);
    };
    ClientsService.prototype.getStaffList = function (masterData) {
        return this.commonService.post(this.getAllStaffListtURL, masterData);
    };
    ClientsService.prototype.getMasterStatus = function (masterData) {
        return this.commonService.post(this.getMasterStatusListURL, masterData);
    };
    ClientsService.prototype.getMasterMealCategory = function (masterData) {
        return this.commonService.post(this.getMasterMealCategoryListURL, masterData);
    };
    // getMasterMealItem(masterData: any): Observable<any> {
    ClientsService.prototype.getAllLocalResourcesMasterForDropDown = function (masterData) {
        return this.commonService.post(this.getAllLocalResourcesMasterForDropDownURL, masterData);
    };
    ClientsService.prototype.getMasterMealItem = function (masterData) {
        return this.commonService.post(this.getMasterMealItemListURL, masterData);
    };
    ClientsService.prototype.getMasterTypeOfFood = function (masterData) {
        return this.commonService.post(this.getMasterTypeofFoodListURL, masterData);
    };
    ClientsService.prototype.getMasterFluidConsistency = function (masterData) {
        return this.commonService.post(this.getMasterFluidConsistencyListURL, masterData);
    };
    ClientsService.prototype.getIncomingReferral = function (masterData) {
        return this.commonService.post(this.getIncomingReferralListURL, masterData);
    };
    ClientsService.prototype.getMasterDataForResidentTransferTo = function (masterData) {
        return this.commonService.post("api/MasterData/MasterDataByName", masterData);
    };
    ClientsService.prototype.getCognitiveStatusCarePlan = function (patientId) {
        return this.commonService.getAll(this.getCognitiveStatusCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createBehavioursCarePlan = function (data) {
        return this.commonService.post(this.createBehavioursCarePlanURL, data);
    };
    ClientsService.prototype.getBehavioursCarePlan = function (patientId) {
        return this.commonService.getAll(this.getBehavioursCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createDressingCarePlan = function (data) {
        return this.commonService.post(this.createDressingCarePlanURL, data);
    };
    ClientsService.prototype.getDressingCarePlan = function (patientId) {
        return this.commonService.getAll(this.getDressingCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createSafetyCarePlan = function (data) {
        return this.commonService.post(this.createSafetyCarePlanURL, data);
    };
    ClientsService.prototype.getSafetyCarePlan = function (patientId) {
        return this.commonService.getAll(this.getSafetyCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createBathingCarePlan = function (data) {
        return this.commonService.post(this.createBathingCarePlanURL, data);
    };
    ClientsService.prototype.getBathingCarePlan = function (patientId) {
        return this.commonService.getAll(this.getBathingCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createNutritionCarePlan = function (data) {
        return this.commonService.post(this.createNutritionCarePlanURL, data);
    };
    ClientsService.prototype.getNutritionCarePlan = function (patientId) {
        return this.commonService.getAll(this.getNutritionCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createHygieneCarePlan = function (data) {
        return this.commonService.post(this.createHygieneCarePlanURL, data);
    };
    ClientsService.prototype.getHygieneCarePlan = function (patientId) {
        return this.commonService.getAll(this.getHygieneCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createSkinCarePlan = function (data) {
        return this.commonService.post(this.createSkinCarePlanURL, data);
    };
    ClientsService.prototype.getSkinCarePlan = function (patientId) {
        return this.commonService.getAll(this.getSkinCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createMobilityCarePlan = function (data) {
        return this.commonService.post(this.createMobilityCarePlanURL, data);
    };
    ClientsService.prototype.getMobilityCarePlan = function (patientId) {
        return this.commonService.getAll(this.getMobilityCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createTransferCarePlan = function (data) {
        return this.commonService.post(this.createTransferCarePlanURL, data);
    };
    ClientsService.prototype.getTransferCarePlan = function (patientId) {
        return this.commonService.getAll(this.getTransferCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createToiletingCarePlan = function (data) {
        return this.commonService.post(this.createToiletingCarePlanURL, data);
    };
    ClientsService.prototype.getToiletingCarePlan = function (patientId) {
        return this.commonService.getAll(this.getToiletingCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createBladderCarePlan = function (data) {
        return this.commonService.post(this.createBladderCarePlanURL, data);
    };
    ClientsService.prototype.getBladderCarePlan = function (patientId) {
        return this.commonService.getAll(this.getBladderCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createBowelCarePlan = function (data) {
        return this.commonService.post(this.createBowelCarePlanURL, data);
    };
    ClientsService.prototype.getBowelCarePlan = function (patientId) {
        return this.commonService.getAll(this.getBowelCarePlanURL + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.changeTaskStatus = function (taskId, statusId) {
        return this.commonService.getAll(this.changeTaskStatusURL +
            "?taskId=" +
            taskId +
            "&taskStatusId=" +
            statusId, {});
    };
    ClientsService.prototype.getrelationshipData = function (SearchKey) {
        return this.commonService.getAll(this.getrelationshipDataURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getSubjectiveList = function (SearchKey) {
        return this.commonService.getAll(this.getSubjectiveListListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getRouteList = function (SearchKey) {
        return this.commonService.getAll(this.getRouteListListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getReasonList = function (SearchKey) {
        return this.commonService.getAll(this.getReasonListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getFrequencyList = function (SearchKey) {
        return this.commonService.getAll(this.getFrequencyListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getMedicationsList = function (SearchKey) {
        return this.commonService.getAll(this.getMedicationsListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getALlInventory = function (filterModel) {
        return this.commonService.post(this.getAllInventoryList, filterModel);
    };
    ClientsService.prototype.GetConsentList = function (filterModel) {
        return this.commonService.post(this.GetAllConcentList, filterModel);
    };
    ClientsService.prototype.getAllInvantoryTrack = function (filterModel) {
        return this.commonService.post(this.getAllInventoryTrackList, filterModel);
    };
    ClientsService.prototype.getAllReorderTrack = function (filterModel) {
        return this.commonService.post(this.getAllReorderTrackList, filterModel);
    };
    ClientsService.prototype.getInventoryTrackById = function (id) {
        return this.commonService.getById(this.GetByInventoryTrackIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.getDrugnameList = function (SearchKey) {
        return this.commonService.getAll(this.getDrugNameURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getLanguage = function (SearchKey) {
        return this.commonService.getAll(this.getMasterLanguages + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getInventoryName = function (SearchKey) {
        return this.commonService.getAll(this.getInventoryNameURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.createUpdateStockOut = function (model) {
        return this.commonService.post(this.saveUpdateStockOutUrl, model);
    };
    ClientsService.prototype.GetPatientName = function (SearchKey) {
        return this.commonService.getAll(this.GetPatientNameURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.deleteInventoryMaster = function (id) {
        return this.commonService.patch(this.deleteInventorymasterURL + "?Id=" + id, {});
    };
    ClientsService.prototype.deleteIncomingFax = function (fileName) {
        return this.commonService.patch(this.deleteIncomingFaxURL + "?fileName=" + fileName, {});
    };
    ClientsService.prototype.saveIncomingFaxDocument = function (data) {
        return this.commonService.post(this.saveIncomingFaxURL, data);
    };
    ClientsService.prototype.getProductsList = function (SearchKey) {
        return this.commonService.getAll(this.getProductsListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getReligionalList = function (SearchKey) {
        return this.commonService.getAll(this.getReligionListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getUnitList = function (SearchKey) {
        return this.commonService.getAll(this.getUnitListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.GetAssessmentList = function (SearchKey) {
        return this.commonService.getAll(this.getGetAssessmentListURL + "?SearchKey=" + SearchKey, {});
    };
    ClientsService.prototype.getAllergyTypeList = function (searchText) {
        var queryParams = "?SearchKey=" + searchText;
        return this.commonService.getAll(this.getAllergyTypeListtURL + queryParams, {});
    };
    ClientsService.prototype.getallergenList = function (searchText) {
        var queryParams = "?SearchKey=" + searchText;
        return this.commonService.getAll(this.getAllernListtURL + queryParams, {});
    };
    ClientsService.prototype.getdiagnosisList = function (searchText) {
        var queryParams = "?SearchKey=" + searchText;
        return this.commonService.getAll(this.getDiagnosisListtURL + queryParams, {});
    };
    ClientsService.prototype.getSeverityList = function (searchText) {
        var queryParams = "?SearchKey=" + searchText;
        return this.commonService.getAll(this.getSeverityListtURL + queryParams, {});
    };
    ClientsService.prototype.getallergyStatusList = function (searchText) {
        var queryParams = "?SearchKey=" + searchText;
        return this.commonService.getAll(this.getAllergyStatusListURL + queryParams, {});
    };
    ClientsService.prototype.getEmrPatientList = function (filterModel) {
        var url = this.getEMRPatientListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&searchDate=" +
            filterModel.searchDate +
            "&UnitId=" +
            filterModel.unitId +
            "&Date=" +
            filterModel.date;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEmrPatientDateFilterList = function (filterModel) {
        var url = this.getEMRPatientDateListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&searchTime=" +
            filterModel.searchTime +
            "&UnitId=" +
            filterModel.unitId +
            "&SearchDate=" +
            filterModel.date +
            "&PrescriptionTypeId=" +
            filterModel.prescriptionTypeId +
            "&ShiftStartDate=" +
            filterModel.shiftStartDate +
            "&ShiftEndDate=" +
            filterModel.shiftEndDate;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEMRPatientMedicationPassProgressList = function (filterModel) {
        var url = this.getEMRPatientMedicationPassProgressListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&searchDate=" +
            filterModel.searchDate +
            "&searchTime=" +
            filterModel.searchDdateTime;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEMRPatientMissedMedicationList = function (filterModel) {
        var url = this.getEMRPatientMissedMedicationListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&fromDate=" +
            filterModel.fromDate +
            "&toDate=" +
            filterModel.toDate;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getMissedMedicationListNotification = function (filterModel) {
        var url = this.getEMRPatientMissedMedicationListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEMRPatientReminderList = function (filterModel) {
        var url = this.getEMRPatientReminderListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&SearchDate=" +
            filterModel.searchDate +
            "&SearchText=" +
            filterModel.searchText;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEMRPatientDiscountinueOrderList = function (filterModel) {
        var url = this.getEMRPatientMedicationDiscontinueOrderListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&searchText=" +
            filterModel.searchText +
            "&fromDate=" +
            filterModel.fromDate +
            "&toDate=" +
            filterModel.toDate;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEMRPatientMedicationIncidentReportLogList = function (filterModel) {
        var url = this.getEMRPatientMedicationIncidentReportLogListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&fromDate=" +
            filterModel.fromDate +
            "&toDate=" +
            filterModel.toDate;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEMRMedicationReorderList = function (filterModel) {
        var url = this.getEMRMedicationReorderListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&fromDate=" +
            filterModel.fromDate +
            "&toDate=" +
            filterModel.toDate;
        return this.commonService.getAll(url, {});
    };
    // getOutgoingReferralList(filterModel: FilterModel) {
    //   let url =
    //     this.getOutgoingReferralListURL +
    //     "?pageNumber=" +
    //     filterModel.pageNumber +
    //     "&pageSize=" +
    //     filterModel.pageSize +
    //     "&sortColumn=" +
    //     filterModel.sortColumn +
    //     "&sortOrder=" +
    //     filterModel.sortOrder;
    //   return this.commonService.getAll(url, {});
    // }
    ClientsService.prototype.getOutgoingReferralList = function (filterModel) {
        return this.commonService.post(this.getOutgoingReferralListURL, filterModel);
    };
    ClientsService.prototype.getIncomingReferralList = function (filterModel) {
        return this.commonService.post(this.getIncomingReferralDataURL, filterModel);
    };
    ClientsService.prototype.getFaxAchievesList = function (filterModel) {
        return this.commonService.post(this.getFaxAchievesDataURL, filterModel);
    };
    ClientsService.prototype.getCurrentMedicationList = function (filterModel) {
        var url = this.getAllCurrentMedicationPrescribingHistoryURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&patientId=" +
            filterModel.patientId +
            "&searchDate=" +
            filterModel.searchDate;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getPreviousMedicationList = function (filterModel) {
        var url = this.getAllPreviousMedicationPrescribingHistoryURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&patientId=" +
            filterModel.patientId +
            "&fromDate=" +
            filterModel.fromDate +
            "&toDate=" +
            filterModel.toDate +
            "&searchText=" +
            filterModel.searchText;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getEMRPatientPRNAdministeredList = function (filterModel) {
        var url = this.getEMRPatientPRNAdministeredListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&searchText=" +
            filterModel.searchText +
            "&fromDate=" +
            filterModel.fromDate +
            "&toDate=" +
            filterModel.toDate;
        return this.commonService.getAll(url, {});
    };
    // deleteCurrentMedication(id: number) {
    //   return this.commonService.post(this.deleteCurrentmedicationURL + "?id=" + id, {});
    // }
    ClientsService.prototype.discontinueCurrentMedication = function (id, discontinueReasonId) {
        return this.commonService.post(this.discontinueCurrentmedicationURL + "?id=" + id + "&discontinueReasonId=" + discontinueReasonId, {});
    };
    ClientsService.prototype.getEMRPatientMedicationOrderChangeList = function (filterModel) {
        var url = this.getEMRPatientMedicationOrderChangeListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.deleteCurrentMedication = function (id) {
        return this.commonService.post(this.deleteCurrentmedicationURL + "?id=" + id, {});
    };
    ClientsService.prototype.getEmrDetails = function (data) {
        return this.commonService.post(this.GetEmrDetailsURL, data);
    };
    ClientsService.prototype.getEmrtimeDetails = function (data) {
        return this.commonService.post(this.GetEmrtimeDetailsURL, data);
    };
    ClientsService.prototype.getFoodDiaryDetails = function (data) {
        return this.commonService.post(this.GetFoodDiaryDetailsURL, data);
    };
    ClientsService.prototype.getFoodDiaryDetailsByPatientId = function (data) {
        return this.commonService.post(this.GetFoodDiaryDetailsByPatientIdURL, data);
    };
    ClientsService.prototype.getEmrDetails1 = function (PatientId) {
        return this.commonService.getById(this.GetEmrDetailsURL1 + "?PatientId=" + PatientId, {});
    };
    //EAMR Medication Method
    ClientsService.prototype.createEMARMedication = function (data) {
        return this.commonService.post(this.createEmarMedicationURL, data);
    };
    ClientsService.prototype.deleteReferrlabyId = function (id) {
        return this.commonService.post(this.deleteReferralURL + "?id=" + id, {});
    };
    ClientsService.prototype.getReferralListById = function (id) {
        return this.commonService.getById(this.getReferralByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.resendReferalById = function (id) {
        return this.commonService.getById(this.resendReferalByIdURL + "?id=" + id, {});
    };
    ClientsService.prototype.getCurrentmedicationById = function (Id) {
        return this.commonService.getById(this.getCurrentmedicationIdURL + "?Id=" + Id, {});
    };
    //EAMR Medication Method
    ClientsService.prototype.getEMARMedicationpopup = function (data) {
        return this.commonService.post(this.geteEmarMedicationURL, data);
    };
    ClientsService.prototype.getMealPlanpopup = function (data) {
        return this.commonService.post(this.geteMealPlanPopUpDataURL, data);
    };
    ClientsService.prototype.getDrugSideEffects = function (drugIds) {
        return this.commonService.getAll(this.GetDrugSideEffectBulk + "?drugId=" + drugIds, {});
    };
    ClientsService.prototype.getDrugdiseass = function (drugIds) {
        return this.commonService.getAll(this.GetDrugDiseaseBulk + "?drugId=" + drugIds, {});
    };
    ClientsService.prototype.GetMinMaxDose = function (drugIds) {
        return this.commonService.postLoader(this.GetMinMaxDoseBulk, drugIds);
    };
    ClientsService.prototype.getDrugIndication = function (data) {
        return this.commonService.getAll(this.GetDrugIndicationBulk + "?drugId=" + data, {});
    };
    ClientsService.prototype.getDrugAllergy = function (data) {
        return this.commonService.getdatata(this.GetDrugAllergyBulk, data);
    };
    ClientsService.prototype.getDrugDrugGeriatricsPrecaution = function (data) {
        return this.commonService.getdatata(this.GetDrugGeriatricsPrecautionBulk, data);
    };
    ClientsService.prototype.getDrugFoodFamilyEducationBulk = function (data) {
        return this.commonService.getdatata(this.GetDrugFoodFamilyEducationBulk, data);
    };
    ClientsService.prototype.updateFlagEmrDetails = function (PatientId) {
        return this.commonService.getById(this.updateEmrDetailsURL + "?PatientId=" + PatientId, {});
    };
    ClientsService.prototype.setPasswordbyUserId = function (id) {
        return this.commonService.getAll(this.setPassword + "?UserId=" + id, {});
    };
    ClientsService.prototype.getDrugDrugInteraction = function (data) {
        return this.commonService.getdatata(this.GetDrugDrugIntration, data);
    };
    ClientsService.prototype.getDrugDrugInteractionBulk = function (data) {
        return this.commonService.getdatata(this.GetDrugDrugInterationBulk, data);
    };
    ClientsService.prototype.getPatientDrug = function (id) {
        return this.commonService.getById(this.GetPatientDrugs + "?patientId=" + id, {});
    };
    ClientsService.prototype.getDrugDuplicateTherapyBulk = function (data) {
        return this.commonService.getdatata(this.GetDrugDuplicateTherapyBulk, data);
    };
    ClientsService.prototype.saveUpdateDementia = function (data) {
        return this.commonService.post(this.saveUpdateDementiaUrl, data);
    };
    ClientsService.prototype.saveUpdateHeartFail = function (data) {
        return this.commonService.post(this.saveUpdateHeartFailUrl, data);
    };
    ClientsService.prototype.saveUpdateCOPD = function (data) {
        return this.commonService.post(this.saveUpdateCOPDUrl, data);
    };
    ClientsService.prototype.getDementiaByPatientId = function (patientId) {
        return this.commonService.getById(this.getDementiaByPatientIdUrl + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.getHeartFailByPatientId = function (patientId) {
        return this.commonService.getById(this.getHeartFailByPatientIdUrl + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.getCopdByPatientId = function (patientId) {
        return this.commonService.getById(this.getCopdByPatientIdUrl + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.getCdssMessages = function (patientId) {
        return this.commonService.getById(this.getCdssMessagesUrl + "?patientId=" + patientId, {});
    };
    ClientsService.prototype.createMasterTax = function (data) {
        return this.commonService.post(this.createMasterTaxURL, data);
    };
    ClientsService.prototype.createMasterDiscount = function (data) {
        return this.commonService.post(this.createMasterDiscountURL, data);
    };
    ClientsService.prototype.createSubscriptionPayment = function (data) {
        return this.commonService.post(this.subscriptionDetailsURL, data);
    };
    ClientsService.prototype.validate = function (userName, userId) {
        if (userId === void 0) { userId = 0; }
        return this.commonService.getAll(this.checkIfExistUrl + userName + "&userID=" + userId, {});
    };
    ClientsService.prototype.getFormByCategoryId = function (id) {
        return this.commonService.getAll(this.getFromByCategoryIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.getFormByProviderId = function (id) {
        return this.commonService.getAll(this.getFromByProviderIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.getImagesWithField = function (id) {
        var url = this.getImagesWithFieldURL + "?Id=" + id;
        return this.commonService.getById(url, {});
    };
    ClientsService.prototype.pdfResponseSubmit = function (model) {
        return this.commonService.post(this.pdfResponseSubmitURL, model);
    };
    ClientsService.prototype.pdfResponseSubmitListing = function (model) {
        return this.commonService.post(this.pdfResponseSubmitListingURL, model);
    };
    ClientsService.prototype.resubmitFormById = function (id) {
        return this.commonService.getAll(this.ResubmitFormByIdURL + "?Id=" + id, {});
    };
    ClientsService.prototype.reviewLabResult = function (model) {
        return this.commonService.post(this.reviewLabResultURL, model);
    };
    ClientsService.prototype.reviewPrn = function (model) {
        return this.commonService.post(this.reviewPrnUrl, model);
    };
    ClientsService.prototype.deletePDFFormById = function (id) {
        return this.commonService.getAll(this.deletePDFFormURL + "?Id=" + id, {});
    };
    ClientsService.prototype.GetStaffAvailableTime = function (date, staffId, locationId, shiftId) {
        return this.commonService.getById(this.getStaffAvailableTimeURL +
            "?date=" +
            date +
            "&staffId=" +
            staffId +
            "&locationId=" +
            locationId +
            "&shiftId=" +
            shiftId +
            "&isLeaveNeeded=false", {});
    };
    ClientsService.prototype.getAvailableDates = function (data) {
        return this.commonService.getdatata(this.GetStaffAvailabilityMobileURL, data);
    };
    ClientsService.prototype.getOpenShifts = function (data) {
        return this.commonService.getdatata(this.GetOpenShiftURL, data);
    };
    ClientsService.prototype.getStaffAvailability = function (data) {
        return this.commonService.getdatata(this.GetStaffAvailabilityURL, data);
    };
    ClientsService.prototype.getStaffUnAvailability = function (data) {
        return this.commonService.getdatata(this.GetStaffUnAvailabilityURL, data);
    };
    ClientsService.prototype.approveStaffAvailability = function (id) {
        return this.commonService.post(this.approveStaffAvailabilityURL + "?Id=" + id, {});
    };
    ClientsService.prototype.approveStaffUnAvailability = function (id) {
        return this.commonService.post(this.approveStaffUnAvailabilityURL + "?Id=" + id, {});
    };
    ClientsService.prototype.getColorById = function (Id) {
        return this.commonService.getById(this.GetColorByIdUrl + "?id=" + Id, {});
    };
    ClientsService.prototype.UpdateColorName = function (modalData) {
        return this.commonService.post(this.UpdateColorUrl, modalData);
    };
    ClientsService.prototype.delete = function (id) {
        return this.commonService.patch(this.deleteURL + "?notificationId=" + id, {});
    };
    ClientsService.prototype.deleteMissedMedicationNotification = function (id) {
        return this.commonService.patch(this.deleteMissedMedNotificationURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteTaskNotification = function (id) {
        return this.commonService.patch(this.deleteTaskNotificationURL + "?taskId=" + id, {});
    };
    ClientsService.prototype.deleteStaffExpiredDocNotification = function (id) {
        return this.commonService.patch(this.deleteStaffExpiredDocNotificationURL + "?id=" + id, {});
    };
    ClientsService.prototype.deleteResidentExpiredDocNotification = function (id) {
        return this.commonService.patch(this.deleteResidentExpiredDocNotificationURL + "?id=" + id, {});
    };
    ClientsService.prototype.getPatientsList = function (filterModel) {
        var url = this.getPatientListURL +
            "?pageNumber=" +
            filterModel.pageNumber +
            "&pageSize=" +
            filterModel.pageSize +
            "&sortColumn=" +
            filterModel.sortColumn +
            "&sortOrder=" +
            filterModel.sortOrder +
            "&unitId=" +
            filterModel.unitIds +
            "&clientId=" +
            filterModel.clientIds +
            "&reportId=" +
            filterModel.reportIds +
            "&fromDate=" +
            filterModel.fromDate +
            "&toDate=" +
            filterModel.toDate;
        return this.commonService.getAll(url, {});
    };
    ClientsService.prototype.getPatientReportDetails = function (clientId, reportIds, fromDate, toDate) {
        var url = this.getPatientReportDetailsURL +
            "?patientId=" +
            clientId +
            "&reportIds=" +
            reportIds +
            "&fromDate=" +
            fromDate +
            "&toDate=" +
            toDate;
        return this.commonService.getAll(url, {});
    };
    //for Notifications
    ClientsService.prototype.turnOffReadedNotification = function (id, messageType) {
        return this.commonService.post(this.TurnOffNotificationListURL + "?id=" + id + "&messageType=" + messageType, {});
    };
    ClientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientsService_Factory() { return new ClientsService(i0.ɵɵinject(i1.CommonService)); }, token: ClientsService, providedIn: "root" });
    return ClientsService;
}());
export { ClientsService };
