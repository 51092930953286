import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";
import base64 from "base-64";
import utf8 from "utf8";
var CommonService = /** @class */ (function () {
    function CommonService(http) {
        var _this = this;
        this.http = http;
        this.SytemInfoSubject = new BehaviorSubject({});
        this.sytemInfo = this.SytemInfoSubject.asObservable().pipe(distinctUntilChanged());
        this.documentUrl = "";
        this.getHeaderNotificationSubject$ = new Subject();
        this.loginUserSubject = new BehaviorSubject({});
        this.loginUser = this.loginUserSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.currentLoginUserInfoSubject = new BehaviorSubject(null);
        this.currentLoginUserInfo = this.currentLoginUserInfoSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.loginSecuritySubject = new BehaviorSubject({});
        this.loginSecurity = this.loginSecuritySubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.isAuthenticatedSubject = new ReplaySubject(1);
        this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
        this.loadingStateSubject = new Subject();
        this.loadingState = this.loadingStateSubject.asObservable();
        // for update the client side navigations ...
        this.updateClientNavigationSubject = new BehaviorSubject({});
        this.updateClientNavigation = this.updateClientNavigationSubject
            .asObservable()
            .pipe(distinctUntilChanged());
        this.updateClientHeader = new Subject();
        this._updateClientHeaderObs = this.updateClientHeader.asObservable();
        this.getDateTimeString = function (date) {
            if (!date)
                return "";
            var dt = new Date(date);
            var year = _this.padZero(dt.getFullYear(), 4);
            var month = _this.padZero(dt.getMonth() + 1, 2);
            var day = _this.padZero(dt.getDate(), 2);
            var hours = _this.padZero(dt.getHours(), 2);
            var minutes = _this.padZero(dt.getMinutes(), 2);
            var seconds = _this.padZero(dt.getSeconds(), 2);
            return year + "-" + month + "-" + day + "T" + "00" + ":" + "00" + ":" + "00" + ".000";
        };
        this.padZero = function (value, width) {
            var val = "" + value;
            if (width > val.length)
                return val.padStart(width, "0");
            return val;
        };
        SystemIpAddress.then(function (value) {
            _this.SytemInfoSubject.next({ ipAddress: value });
        }).catch(function (e) { return console.error(e); });
        // this.http
        //   .get("https://api.ipify.org/?format=json")
        //   .subscribe((res: any) => {
        //     this.SytemInfoSubject.next({ ipAddress: res.ip });
        //   });
    }
    CommonService.prototype.getOrganizationTypeName = function () {
        if (localStorage.getItem("business_token_object")) {
            var tokenObject = JSON.parse(localStorage.getItem("business_token_object"));
            // console.log('organizationTypeName',tokenObject.organization.organizationTypeName)
            return tokenObject.organization.organizationTypeName;
        }
    };
    CommonService.prototype.getOrganizationTypeTabName = function () {
        if (localStorage.getItem("business_token_object")) {
            var tokenObject = JSON.parse(localStorage.getItem("business_token_object"));
            // console.log(
            //   "organizationTypeTabName",
            //   tokenObject.organization.organizationTypeTabName
            // );
            return tokenObject.organization.organizationTypeTabName;
        }
    };
    CommonService.prototype.getOrganizationName = function () {
        if (localStorage.getItem("business_token_object")) {
            var tokenObject = JSON.parse(localStorage.getItem("business_token_object"));
            // console.log(
            //   "organizationName",
            //   tokenObject.organization.organizationName
            // );
            return tokenObject.organization.organizationName;
        }
    };
    CommonService.prototype.getClientIPAddress = function () {
        return this.http.get("https://api.ipify.org/?format=json", {
            headers: { skip: "true" },
        });
    };
    // Allow only alphabet
    CommonService.prototype.allowAlphabetOnly = function (res) {
        var regexp = new RegExp('^[0-9!@#$&()\\-`.+,/"]*$');
        var result = regexp.test(res.key);
        if (result) {
            res.preventDefault();
            return false;
        }
    };
    /** Allow only Number */
    CommonService.prototype.allowOnlyNumber = function (res) {
        var regexp = new RegExp("^([0-9])$");
        var result = regexp.test(res.key);
        if (!result) {
            res.preventDefault();
            return false;
        }
    };
    /* Allow only Number with decimal point */
    CommonService.prototype.allowOnlyNumberWithDecimal = function (res) {
        var regexp = new RegExp(/^\d*\.?\d*$/);
        var result = regexp.test(res.key);
        if (!result) {
            res.preventDefault();
            return false;
        }
    };
    CommonService.prototype.initializeAuthData = function () {
        var _this = this;
        if (localStorage.getItem("access_token")) {
            return this.http
                .get(environment.api_url + "/GetUserByToken")
                .subscribe(function (response) {
                // login successful if there's a jwt token in the response
                if (response && response.access_token) {
                    _this.setAuth(response);
                }
                else {
                    //console.log('c');
                    _this.purgeAuth();
                }
                return response;
            });
        }
    };
    CommonService.prototype.isValidFileType = function (fileName, fileType) {
        // Create an object for all extension lists
        var extentionLists = { video: [], image: [], pdf: [], excel: [], xml: [] };
        var isValidType = false;
        extentionLists.video = ["m4v", "avi", "mpg", "mp4"];
        extentionLists.image = ["jpg", "jpeg", "bmp", "png", "ico"];
        extentionLists.pdf = ["pdf"];
        extentionLists.excel = ["excel"];
        extentionLists.xml = ["xml"];
        //get the extension of the selected file.
        var fileExtension = fileName.split(".").pop().toLowerCase();
        isValidType = extentionLists[fileType].indexOf(fileExtension) > -1;
        return isValidType;
    };
    CommonService.prototype.logout = function () {
        //console.log('c1');
        // remove user from local storage to log user out
        this.purgeAuth();
    };
    CommonService.prototype.setAuth = function (user) {
        localStorage.setItem("access_token", JSON.stringify(user.access_token));
        // Set current user data into observable
        this.loginUserSubject.next(user);
        if (user.data) {
            this.currentLoginUserInfoSubject.next(tslib_1.__assign({}, user.data, { currentLocationId: user.data.staffLocation &&
                    user.data.staffLocation[0] &&
                    user.data.staffLocation[0].locationID, userLocations: user.userLocations || [] }));
        }
        // Set isAuthenticated to true
        this.isAuthenticatedSubject.next(true);
    };
    CommonService.prototype.purgeAuth = function () {
        localStorage.removeItem("access_token");
        // Set current user to an empty object
        this.loginUserSubject.next({});
        this.currentLoginUserInfoSubject.next(null);
        // Set auth status to false
        this.isAuthenticatedSubject.next(false);
    };
    CommonService.prototype.setSecurityQuestions = function (securityQuestionObj) {
        this.loginSecuritySubject.next(securityQuestionObj);
    };
    CommonService.prototype.getLoginUserInfo = function () {
        return this.loginUserSubject.value;
    };
    CommonService.prototype.getCurrentLoginLocationId = function () {
        var locationId = "";
        var loginData = this.currentLoginUserInfoSubject.value;
        if (loginData) {
            locationId = loginData.currentLocationId || 0;
        }
        return locationId.toString();
    };
    CommonService.prototype.getSystemIpAddress = function () {
        return this.SytemInfoSubject.value && this.SytemInfoSubject.value.ipAddress;
    };
    CommonService.prototype.updateCurrentLoginUserInfo = function (locationId) {
        var loginData = this.currentLoginUserInfoSubject.value;
        if (loginData) {
            var newUserObj = tslib_1.__assign({}, loginData, { currentLocationId: locationId });
            this.currentLoginUserInfoSubject.next(newUserObj);
        }
    };
    CommonService.prototype.updateClientNaviagations = function (clientId, userId) {
        if (userId === void 0) { userId = null; }
        this.updateClientNavigationSubject.next({ clientId: clientId, userId: userId });
    };
    Object.defineProperty(CommonService.prototype, "getAdditionalHeaders", {
        get: function () {
            var additionalHeaders = JSON.stringify({
                Offset: new Date().getTimezoneOffset().toString(),
                Timezone: calculateTimeZone(),
                IPAddress: this.getSystemIpAddress(),
                LocationID: this.getCurrentLoginLocationId(),
            });
            // console.log("Additional headers", additionalHeaders);
            return additionalHeaders;
        },
        enumerable: true,
        configurable: true
    });
    CommonService.prototype.post = function (url, data) {
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        return this.http.post(environment.api_url + "/" + url, data, {
            headers: headers,
        });
    };
    CommonService.prototype.put = function (url, data) {
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        return this.http.put(environment.api_url + "/" + url, data, {
            headers: headers,
        });
    };
    CommonService.prototype.getById = function (url, data, isLoading) {
        var _this = this;
        if (isLoading === void 0) { isLoading = true; }
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        isLoading && this.loadingStateSubject.next(true);
        return this.http
            .get(environment.api_url + "/" + url, { headers: headers })
            .pipe(map(function (res) {
            isLoading && _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.getAll = function (url, data, isLoading) {
        var _this = this;
        if (isLoading === void 0) { isLoading = true; }
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        isLoading && this.loadingStateSubject.next(true);
        return this.http
            .get(environment.api_url + "/" + url, { headers: headers })
            .pipe(map(function (res) {
            isLoading && _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.getAllWithputLoader = function (url, data, isLoading) {
        if (isLoading === void 0) { isLoading = true; }
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        // isLoading && this.loadingStateSubject.next(true);
        return this.http
            .get(environment.api_url + "/" + url, { headers: headers })
            .pipe(map(function (res) {
            // isLoading && this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.getdatata = function (url, data, isLoading) {
        var _this = this;
        if (isLoading === void 0) { isLoading = true; }
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        isLoading && this.loadingStateSubject.next(true);
        return this.http
            .post(environment.api_url + "/" + url, data, { headers: headers })
            .pipe(map(function (res) {
            isLoading && _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.postLoader = function (url, data, isLoading) {
        var _this = this;
        if (isLoading === void 0) { isLoading = true; }
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        isLoading && this.loadingStateSubject.next(true);
        return this.http
            .post(environment.api_url + "/" + url, data, { headers: headers })
            .pipe(map(function (res) {
            isLoading && _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.delete = function (url, data) {
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        return this.http.delete(environment.api_url + "/" + url, {
            headers: headers,
        });
    };
    CommonService.prototype.patch = function (url, data, isLoading) {
        var _this = this;
        if (isLoading === void 0) { isLoading = true; }
        var headers = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        isLoading && this.loadingStateSubject.next(true);
        return this.http
            .patch(environment.api_url + "/" + url, data, { headers: headers })
            .pipe(map(function (res) {
            isLoading && _this.loadingStateSubject.next(false);
            return res;
        }));
    };
    CommonService.prototype.download = function (url, headers) {
        var ccdaheaders = new HttpHeaders({
            additionalHeaders: this.getAdditionalHeaders,
        });
        return this.http.get(environment.api_url + "/" + url, {
            headers: ccdaheaders,
            responseType: "blob",
        });
    };
    CommonService.prototype.calculateFileSizehandler = function (files) {
        var totalFileSize = 0;
        var fileSizeInKB;
        // @desc - define const max upload file size 5MB
        var totalFileSizeInMB;
        var fileSize5MB = "5.00";
        files.map(function (obj, i) {
            totalFileSize = totalFileSize + obj.size;
            totalFileSizeInMB = (totalFileSize / 1024 / 1024).toFixed(2);
            fileSizeInKB = Math.round(obj.size / 1024); // converted bytes(incoming file size) to KB.
            // 1024kb = 1MB
            var fileSizeInMB = Math.round(obj.size / 1024 / 1024);
            var fileSizeInMBFixTo2Decimal = fileSizeInMB.toFixed(2);
            return fileSizeInKB >= 1024
                ? fileSizeInMBFixTo2Decimal + "MB"
                : fileSizeInKB + "KB";
        });
        if (parseFloat(fileSizeInKB) === 0.0 ||
            parseFloat(totalFileSizeInMB) > parseFloat(fileSize5MB)) {
            return false;
        }
        else {
            return true;
        }
    };
    CommonService.prototype.encryptValue = function (value, isEncrypt) {
        if (isEncrypt === void 0) { isEncrypt = true; }
        var response;
        if (value != null && value != "") {
            var pwd = "HCPRODUCT#!_2018@";
            var bytes = void 0;
            if (isEncrypt) {
                bytes = utf8.encode(value.toString());
                response = base64.encode(bytes);
                //response = CryptoJS.AES.encrypt(JSON.stringify(value), pwd);
            }
            else {
                bytes = base64.decode(value);
                response = utf8.decode(bytes);
                // const bytes = CryptoJS.AES.decrypt(value, pwd);
                // if (bytes.toString()) {
                //   response= JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                // }
                //      response = CryptoJS.AES.decrypt(value, pwd);//.toString(CryptoJS.enc.Utf8);
            }
        }
        return response;
    };
    CommonService.prototype.getUserScreenActionPermissions = function (moduleName, screenName) {
        var returnObj = {};
        var loginData = this.loginUserSubject.value;
        if (loginData.userPermission) {
            var modules = loginData.userPermission.modulePermissions || [], screens = loginData.userPermission.screenPermissions || [], actions = loginData.userPermission.actionPermissions || [], userRoleName = loginData.data.users3 && loginData.data.users3.userRoles.userType, isAdminLogin_1 = (userRoleName || "").toUpperCase() === "ADMIN";
            var moduleobj_1 = modules.find(function (obj) { return obj.moduleKey == moduleName; });
            var screenObj_1 = screens.find(function (obj) {
                return obj.screenKey == screenName &&
                    obj.moduleId == (moduleobj_1 && moduleobj_1.moduleId);
            });
            var actionPermissions = actions.filter(function (obj) { return obj.screenId == (screenObj_1 && screenObj_1.screenId); }) || [];
            actionPermissions.forEach(function (obj) {
                returnObj[obj.actionKey] = obj.permission || isAdminLogin_1;
            });
        }
        return returnObj;
    };
    CommonService.prototype.getBusinessUrl = function () {
        var domainName = "";
        if (localStorage.getItem("business-url")) {
            domainName = localStorage.getItem("business-url");
        }
        return domainName;
    };
    CommonService.prototype.getBusinessType = function () {
        var type = "";
        if (localStorage.getItem("business-type")) {
            type = localStorage.getItem("business-type");
        }
        return type;
    };
    CommonService.prototype.isRoutePermission = function (routeName) {
        var businessName = localStorage.getItem("business-url");
        var changedRouteName = routeName.replace("/" + businessName + "/web", "");
        if (changedRouteName.length) {
            var index = changedRouteName.indexOf("?");
            changedRouteName = changedRouteName.substring(0, index != -1 ? index : changedRouteName.length);
        }
        var permission = false;
        var loginData = this.loginUserSubject.value, userRoleName = loginData.data &&
            loginData.data.users3 &&
            loginData.data.users3.userRoles.userType;
        if (loginData.userPermission &&
            ((userRoleName || "").toUpperCase() == "STAFF" ||
                (userRoleName || "").toUpperCase() == "ADMIN")) {
            var modules = loginData.userPermission.modulePermissions || [], screens = loginData.userPermission.screenPermissions || [], isAdminLogin = (userRoleName || "").toUpperCase() == "ADMIN";
            var moduleobj = modules.find(function (obj) { return obj.navigationLink == changedRouteName; });
            var templateFormModuleObj = modules.filter(function (item) { return item.moduleKey == "ORGANIZATION_TEMPLATE_FORMS"; });
            if (moduleobj) {
                permission = moduleobj.permission || isAdminLogin;
            }
            else if ((templateFormModuleObj.length > 0 && templateFormModuleObj[0].permission == true) || isAdminLogin) {
                permission = true;
            }
            else {
                // routing changes due to some conditions ....
                if (changedRouteName.includes("/Masters"))
                    changedRouteName = changedRouteName.replace("/Masters", "");
                if (changedRouteName.includes("/Manage-Users"))
                    changedRouteName = changedRouteName.replace("/Manage-Users", "");
                if (changedRouteName.includes("/Billing"))
                    changedRouteName = changedRouteName.replace("/Billing", "");
                if (changedRouteName.includes("/Logs"))
                    changedRouteName = changedRouteName.replace("/Logs", "");
                var screenObj = screens.find(function (obj) { return obj.navigationLink == changedRouteName; });
                permission = (screenObj && screenObj.permission) || isAdminLogin;
            }
            if (changedRouteName == "/user" && !permission) {
                var encryptId = decodeURIComponent(routeName).replace("/web/Manage-Users/user?id=", ""), decUserId = encryptId &&
                    !encryptId.includes("/web/Manage-Users/user") &&
                    this.encryptValue(encryptId, false);
                if (decUserId == loginData.data.id)
                    permission = true;
            }
        }
        return permission;
    };
    CommonService.prototype.isRoutePermissionForClient = function (routeName) {
        routeName = routeName.replace("/web", "");
        if (routeName.length) {
            var index = routeName.indexOf("?");
            routeName = routeName.substring(0, index != -1 ? index : routeName.length);
        }
        var permission = false;
        var businessName = this.getBusinessUrl();
        var loginData = this.loginUserSubject.value, userRoleName = loginData.data &&
            loginData.data.users3 &&
            loginData.data.users3.userRoles.userType;
        if (loginData.userPermission &&
            (userRoleName || "").toUpperCase() == "CLIENT") {
            var screens = [
                {
                    navigationLink: "/" + businessName + "/client/dashboard",
                    permission: true,
                },
                // { navigationLink: '/client/my-scheduling', permission: true },
                {
                    navigationLink: "/" + businessName + "/client/my-profile",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/patient-profile",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/encounter/video-call",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/mailbox",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/assigned-documents",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/billing",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/health-record",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/care-provider",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/care-summary",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/treatment-timeline",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/virtual-consult",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/purchase-order",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/activity-schedules",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/food-dairy",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/presonal-inventory",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/reports",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/service-feedback",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/local-resource",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/patient-documents",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/consent-prefernces",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/labs",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/cart",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/payment-details",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/home-monitoring",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/health",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/allergy",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/diagnosi",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/medications",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/care-plan-goals",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/cares-plans",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/my-appointment",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/virtual-consults",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/nutrition-Calendar",
                    permission: true,
                },
                {
                    navigationLink: "/" + businessName + "/client/my-symptom-tracker",
                    permission: true,
                },
            ];
            var screenObj = screens.find(function (obj) { return obj.navigationLink == routeName; });
            permission = screenObj && screenObj.permission;
        }
        return permission;
    };
    CommonService.prototype.getFaxFileObj = function (faxFileBase64) {
        var dic = [];
        dic.push("\"" + faxFileBase64 + "\": \".pdf\"");
        var newObj = dic.reduce(function (acc, cur, index) {
            acc[index] = cur;
            return acc;
        }, {});
        return newObj;
    };
    return CommonService;
}());
export { CommonService };
var ɵ0 = function (r) {
    var w = window, a = new (w.RTCPeerConnection ||
        w.mozRTCPeerConnection ||
        w.webkitRTCPeerConnection)({ iceServers: [] }), b = function () { };
    a.createDataChannel("");
    a.createOffer(function (c) { return a.setLocalDescription(c, b, b); }, b);
    a.onicecandidate = function (c) {
        try {
            c.candidate.candidate
                .match(/([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g)
                .forEach(r);
        }
        catch (e) { }
    };
};
var SystemIpAddress = new Promise(ɵ0);
function calculateTimeZone(dateInput) {
    var dateObject = dateInput || new Date(), dateString = dateObject + "", tzAbbr = 
    // Works for the majority of modern browsers
    dateString.match(/\(([^\)]+)\)$/) ||
        // IE outputs date strings in a different format:
        dateString.match(/([A-Z]+) [\d]{4}$/);
    if (tzAbbr) {
        // Old Firefox uses the long timezone name (e.g., "Central
        // Daylight Time" instead of "CDT")
        tzAbbr = tzAbbr[1];
    }
    if (tzAbbr.includes("Daylight")) {
        var da = tzAbbr.split(" ");
        var index = da.indexOf("Daylight");
        if (index > -1) {
            da[index] = "Standard";
        }
        tzAbbr = da.toString().replace(/,/g, " ");
    }
    return tzAbbr;
}
export { ɵ0 };
