import { OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { CommonService } from "src/app/platform/modules/core/services";
import { FormBuilder, Validators } from "@angular/forms";
var TermsConditionsComponent = /** @class */ (function () {
    function TermsConditionsComponent(data, commonService, dialogModalRef, formBuilder) {
        this.data = data;
        this.commonService = commonService;
        this.dialogModalRef = dialogModalRef;
        this.formBuilder = formBuilder;
        this.acceptTermsAndConditionsURL = "/Login/UpdateAgreemetnAndFistLoginStatus";
        this.isValid = true;
        //console.log("From data",data)
        this.content = data.content;
    }
    TermsConditionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.acceptTerms = this.formBuilder.group({
            isChecked: ["", Validators.required],
        });
        this.acceptTerms.controls.isChecked.valueChanges.subscribe(function (value) {
            if (value) {
                _this.isValid = false;
            }
            else {
                _this.isValid = true;
            }
        });
    };
    TermsConditionsComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.acceptTerms.invalid) {
            this.commonService
                .post(this.acceptTermsAndConditionsURL, {})
                .subscribe(function (response) {
                if (response && response.statusCode == 200) {
                    _this.closeDialog("ok");
                }
            });
            //console.log("AcceptClick")
        }
    };
    TermsConditionsComponent.prototype.declineTerms = function () {
        this.closeDialog("close");
    };
    TermsConditionsComponent.prototype.closeDialog = function (action) {
        this.dialogModalRef.close(action);
    };
    return TermsConditionsComponent;
}());
export { TermsConditionsComponent };
