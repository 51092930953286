import { CommonService } from "../../core/services";
import * as i0 from "@angular/core";
import * as i1 from "../../core/services/common.service";
var ChangePasswordService = /** @class */ (function () {
    function ChangePasswordService(commonService) {
        this.commonService = commonService;
        this.updateNewPasswordURL = 'user/UpdateUserPassword';
        this.GetPasswordPoliciesURL = "/PasswordPolicies/GetPasswordPolicies";
    }
    ChangePasswordService.prototype.updateNewPassword = function (data) {
        return this.commonService.patch(this.updateNewPasswordURL, data);
    };
    ChangePasswordService.prototype.getPasswordPolicyByToken = function () {
        return this.commonService.getById(this.GetPasswordPoliciesURL, {});
    };
    ChangePasswordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChangePasswordService_Factory() { return new ChangePasswordService(i0.ɵɵinject(i1.CommonService)); }, token: ChangePasswordService, providedIn: "root" });
    return ChangePasswordService;
}());
export { ChangePasswordService };
