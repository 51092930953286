// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // // /** Local Host Please uncomment and use this  */
    // api_url: "http://localhost:51269",
    // base_url: "http://localhost:4200",

  /** Staging Please uncomment and use it */
  // base_url: "https://www.stagingwin.com:9521",
  // api_url: "https://www.stagingwin.com:9522",

  /** Production Please uncomment it and use */
  api_url: "https://accessehealth.com:8080",
  base_url: "https://accessehealth.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/**
 * Other Urls
 *
 * // api_url: "https://stagingwin.com:9451",

  // api_url: 'http://75.126.168.31:6024'
  // // api_url: 'http://54.191.156.102:3333'

  // api_url: "http://localhost:51269",
  // // api_url: 'http://75.126.168.31:9435'
  // api_url: "https://www.stagingwin.com:9522",
  // api_url: "https://accessemrbackend.azurewebsites.net/",

  // api_url: "http://75.126.168.31:9462",
  // base_url: "https://www.stagingwin.com:9521",

  // api_url: 'http://75.126.168.31:6024'
  // api_url: 'http://54.191.156.102:3333'
 */
