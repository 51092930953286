import { Component, OnInit, Inject } from "@angular/core";
import { ResponseModel } from "src/app/super-admin-portal/core/modals/common-model";
import { TaskNotification } from "../../models";
import { NotifierService } from "angular-notifier";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { CommonService } from "src/app/platform/modules/core/services";
import { TaskAcceptComponent } from "../task-accept/task-accept.component";
import { Router } from "@angular/router";

@Component({
  selector: "app-task-completed",
  templateUrl: "./task-completed.component.html",
  styleUrls: ["./task-completed.component.css"],
})
export class TaskCompletedComponent implements OnInit {
  taskId: number;
  patientId: number;
  isResidentSelected:boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private dialogModalRef: MatDialogRef<TaskCompletedComponent>,
    private notifier: NotifierService,
    private router: Router
  ) {
    console.log("TaskId : ", data.taskId);
    console.log("PatientId : ", data.patientId);
    this.taskId = data.taskId;
    this.patientId = data.patientId;
  }
  updateTaskStatus = "api/PatientAppointments/UpdateTaskStatus";
  ngOnInit() {
    if(this.patientId!=0)
    {
      this.isResidentSelected=true;
    }
  }

  updateTask() {
    let statusId = 1;
    this.commonService
      .getAll(
        this.updateTaskStatus +
          "?taskId=" +
          this.taskId +
          "&taskStatusId=" +
          statusId,
        {}
      )
      .subscribe((response: ResponseModel) => {
        if (response && response.statusCode == 200) {
          this.closeDialog("close");
          if ((response.data.taskStatusId = 1)) {
            this.notifier.notify("success", "Task Completed!");
          }
        }
      });
  }

  closeDialog(action: string): void {
    this.dialogModalRef.close(action);
  }
  redirectToForm() {
    let businessName = this.commonService.getBusinessUrl();
    this.router.navigate(["/"+businessName+"/web/client/patient-details"], {
      queryParams: {
        id: this.commonService.encryptValue(this.patientId, true),
        tab: this.commonService.encryptValue("Point of care", true),
        nextTab: this.commonService.encryptValue("TAR", true),
        childTab: this.commonService.encryptValue("Create New", true),
        taskId: this.commonService.encryptValue(this.taskId,true)
      },
    });
    this.closeDialog("close");
  }
}
